import { createSlice } from "@reduxjs/toolkit";

export const wardenSlice = createSlice({
  name: "warden",
  initialState: {
    wardenDetails: [],
    newWarden: [],
    updateWarden: [],
    currentWardenId: "",
  },
  reducers: {
    getWarden: (state, { payload }) => {
      state.wardenDetails = payload;
    },
    updateWarden: (state, { payload }) => {
      state.wardenDetails = { ...state.wardenDetails, payload };
    },
    addNewWarden: (state, { payload }) => {
      state.newWarden = payload;
    },
    setCurrentWardenId: (state, { payload }) => {
      state.currentWardenId = payload;
    },
    setUpdateWarden: (state, { payload }) => {
      state.updateWarden = payload;
    },
  },
});

export const {
  getWarden,
  setUpdateWarden,
  updateWarden,
  addNewWarden,
  setCurrentWardenId,
} = wardenSlice.actions;

export default wardenSlice.reducer;
