import React, { useState } from "react";
import Sidebar from "../Components/Sidebar";
import Navbar from "../Components/Navbar";
import "./WardenProfile.css";
import { db } from "../firebase";
import { doc, collection, addDoc, setDoc } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import { DiceFive } from "phosphor-react";
import { Col, Container, Modal, ModalBody, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete,Input, TextField } from "@mui/material";
import { setPage, setRecaptcha } from "../store/loginSlice";
import { setUpRecaptcha, setUpRecaptchaWithEmail } from "../helpers/setUpRecaptcha";
import { setNewStudent } from "../store/studentsSlice";
import { addNewWarden } from "../store/wardenSlice";
import VerifyModal from "../Components/optValidationModal";
import { addWarden } from "../apis/wardenApi";


const WardenProfile = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [aadhar, setAadhaar] = useState("");
  const [pin, setPin] = useState("");
  const [blockName, setBlockName] = useState("");
  const [gender, setGender] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [blockNumber, setBlockNumber] = useState("");
  const [pinErr,setPinErr]=useState(false);
  const [mobileErr,setMobileErr]=useState(false);
  const [aadharErr,setAadharErr]=useState(false);
  const [otpShow,setOtpShow]=useState(false);
  const [show,setShow]=useState(false);
  const { hostelDetails } = useSelector((state) => state.hostel);
  let hostelNameList = [];
  hostelDetails.map((hostel) => {
    hostel.data.hostelList.map((list) => {
      hostelNameList.push(list);
    });
  });
  

  const newWarden = {
    name: name,
    mobileNumber: mobile,
    address: address,
    dob: dob,
    aadharNumber: aadhar,
    blockName: blockName,
    gender: gender,
    hostelName: hostelName,
    blockNumber: blockNumber,
  };
  const dispatch=useDispatch()
  const handleAdd = async (e) => {
    e.preventDefault()
    if(e.target.checkValidity() && !mobileErr && !aadharErr && !pinErr )
    {
    try {
    const response = await setUpRecaptchaWithEmail(`${mobile}@rgmcet.com`, pin);
    if (response !== undefined) {
      dispatch(setRecaptcha(response))
      dispatch(addNewWarden(newWarden))
      dispatch(setPage("warden"))
      await addWarden(dispatch, {
        newWarden: newWarden,
        uuId: response.user.uid,
      });
      navigate(`/warden`, {
        state: { uid: response.user.uid },
      });
    // setOtpShow(true)
    }
    } catch (err) {
      console.error(err);
    }
  }else{
    alert("Fields Missing !!!")
    return;
  }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate("/warden", {
      state: {
        propsMobile: mobile,
        page: "warden",
        name,
        address,
        dob,
        aadhar,
        blockName,
        gender,
        hostelName,
        blockNumber,
      },
    });
  };
  const handleMobNum=(e)=>{
    setMobile(e.target.value);
    if(e.target.value.length===10){
      setMobileErr(false); 
    }
    else{
      setMobileErr(true);
    }
  }

  const handlePin=(e)=>{
    setPin(e.target.value);
    if(e.target.value.length===6){
      setPinErr(false); 
    }
    else{
      setPinErr(true);
    }
  }

  const handleAadNum=(e)=>{  
    setAadhaar(e.target.value); 
    if(e.target.value.length===12){
      setAadharErr(false)
        }else{
          setAadharErr(true)
        }
      

  }

  return (
    <>
    
    <div className="navmain">
        <Navbar />
      </div>
    <div className="wardenmain">

      <Container>
        <Row>
          <Col sm={12} lg={2}>
            <Sidebar />
          </Col>
          <Col sm={12} lg={10} className="mt-3">
            <Container>
              <Row>
                <Col sm={12}>
                  <div className="heading">
                    <Link to="/home">Dashboard</Link> /{" "}
                    <Link to="/warden">Warden</Link> /
                    <span className="warden-span">&nbsp; Warden Profile</span>
                  </div>
                </Col>
                <Col sm={12}>
                  <h2 style={{ fontFamily: "Inter" }}>Warden Profile</h2>
                  <form className="row warden-form" onSubmit={handleAdd}>
                    <div className="col-lg-4 form-items">
                      <div>
                        <label style={{ fontFamily: "Inter" }}>Name</label>
                        <br />
                        <input
                          required
                          type="text"
                          value={name}
                          onChange={(event) => {
                            setName(event.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter",marginBottom:"0px" }}>
                          Mobile Number
                        </label>
                        {/* <br /> */}
                        <input
                        
                        style={{"-webkit-appereance":"none",border:`1px solid ${mobileErr ? "red ": "white"}`}}
                        type="number"
                        value={mobile}
                        onChange={(e)=>handleMobNum(e)}
                        />
                        
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter" }}>Address</label>
                        <br />
                        <textarea
                          required
                          value={address}
                          onChange={(event) => {
                            setAddress(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 form-items">
                      <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Date of Birth
                        </label>
                        <br />
                        <input
                          required
                          type="text"
                          value={dob}
                          onChange={(event) => {
                            setDob(event.target.value);
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={{ fontFamily: "Inter",marginBottom:"0px" }}>
                          Pin
                        </label>
                        {/* <br /> */}
                        <input
                          required
                          style={{"-webkit-appereance":"none",border:`1px solid ${pinErr ? "red ": "white"}`}}
                          type="number"
                          value={pin}
                          onChange={(e)=>handlePin(e)}
                        />
                        
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Aadhaar Number
                        </label>
                        <br />
                        <input
                        required
                        type="Number"
                        style={{border:`1px solid ${aadharErr ? "red ": "white"}`}}
                        value={aadhar}
                        onChange={(e)=>handleAadNum(e)}
                        />
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Block Name
                        </label>
                        <br />
                        <input
                          required
                          type="text"
                          value={blockName}
                          onChange={(event) => {
                            setBlockName(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 form-items">
                      <div>
                        <label style={{ fontFamily: "Inter" }}>Gender</label>
                        <br />
                        <input
                          required
                          type="text"
                          value={gender}
                          onChange={(event) => {
                            setGender(event.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Hostel Name
                        </label>
                        <br />
                        {/* <input
                          required
                          type="text"
                          value={hostelName}
                          onChange={(event) => {
                            setHostelName(event.target.value);
                          }}
                        /> */}
                        <Autocomplete
                            sx={{ backgroundColor: "inherit" }}
                            value={hostelName}
                            options={hostelNameList}
                            onChange={(event, newValue) =>
                              setHostelName(newValue)
                            }
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <input type="text" {...params.inputProps} />
                              </div>
                            )}
                          />
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Block Number
                        </label>
                        <br />
                        <input
                          required
                          type="text"
                          value={blockNumber}
                          onChange={(event) => {
                            setBlockNumber(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div style={{marginTop:"2rem"}}id="recaptcha-container"></div>
                    <div className="buttons">
                      <button
                      type="submit"
                        className="btn btn-primary"
                      >
                        Add New
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          navigate("/warden");
                        }}
                      >
                        Go Back
                      </button>
                    </div>
                  </form >
                </Col>
              </Row>
              
            </Container>
          </Col>
        </Row>
      </Container>
      <Modal show={otpShow}  onHide={()=>(setOtpShow(false))}>
          <ModalBody >
        <VerifyModal setShow={(val)=>setShow(val)} setOtpShow={(val)=>setOtpShow(val)}/>
          </ModalBody>
        </Modal>
    </div>

    </>
  );
};

export default WardenProfile;
