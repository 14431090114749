import { createSlice } from "@reduxjs/toolkit";

export const outPassSlice = createSlice({
  name: "outPass",
  initialState: {
    outPassDetails: [],
  },
  reducers: {
    getOutPass: (state, { payload }) => {
      state.outPassDetails = payload;
    },
    updateOutPass: (state, { payload }) => {
      state.outPassDetails = { ...state, payload };
    },
  },
});

export const { getOutPass, updateOutPass } = outPassSlice.actions;

export default outPassSlice.reducer;
