import { createSlice } from "@reduxjs/toolkit";

export const hostelSlice = createSlice({
  name: "hostel",
  initialState: {
    hostelDetails: [],
    newHostel: [],
    updateHostel: [],
    currentHostelId: "",
  },
  reducers: {
    getHostel: (state, { payload }) => {
      state.hostelDetails = payload;
    },
    updateHostel: (state, { payload }) => {
      state.hostelDetails = { ...state.hostelDetails, payload };
    },
    addNewHostel: (state, { payload }) => {
      state.newHostel = payload;
    },
    setCurrentHostelId: (state, { payload }) => {
      state.currentHostelId = payload;
    },
    setUpdateHostel: (state, { payload }) => {
      state.updateHostel = payload;
    },
  },
});

export const {
  getHostel,
  setUpdateHostel,
  updateHostel,
  addNewHostel,
  setCurrentHostelId,
} = hostelSlice.actions;

export default hostelSlice.reducer;
