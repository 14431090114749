import React, { useEffect, useState } from "react";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import $ from "jquery";
import "../Components/dataTable.css";
//firebase

import {useSelector,useDispatch} from "react-redux";
import { getAttendanceDetails, updateAttendance } from "../apis/attendanceApi";
// import Pagination from '@mui/material/Pagination';

import DataTable from "../Components/DataTable";


const AttendanceDataTable = (props) => {
   
    // return (
    //     // <div>
    //         <div className="container" style={{paddingTop:"2rem" ,marginBottom:"3rem",width:"160vw",marginLeft:"1rem",left:0}}>
    //           <DataTable Items={items} searchedName={searchedName}/>
             
    //         </div>
    //     // </div>
    // );
};

export default AttendanceDataTable;
