import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { db } from "../firebase";
import { getSecurity } from "../store/securitySlice";
import { useNavigate } from "react-router-dom";

export const getSecurityDetails = async (dispatch, payload) => {
  try {
    const q = query(collection(db, "security"));
    await onSnapshot(q, (querySnapshot) => {
      dispatch(
        getSecurity(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
    });

    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
export const addSecurity = async (dispatch, { newSecurity, uuId }) => {
  try {
    const usersRef = doc(db, "security", uuId);

    const docSnap = await getDoc(usersRef);

    if (docSnap.exists()) {
      alert("Number Already Exist !!!");
    } else {
      const res = await setDoc(doc(db, "security", uuId), { ...newSecurity });

      getSecurityDetails(dispatch);
    }
    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
export const updateSecurityMobile = async (
  dispatch,
  { updateSecurity, uuId, currentSecurityId }
) => {
  try {
    const usersRef = doc(db, "security", uuId);

    const docSnap = await getDoc(usersRef);

    if (docSnap.exists()) {
      alert("Number Already Exist !!!");
    } else {
      const res = await setDoc(doc(db, "security", uuId), {
        ...updateSecurity,
      });

      await deleteSecurity(currentSecurityId);
      await getSecurityDetails(dispatch);
    }

    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
export const deleteSecurity = async (id) => {
  try {
    const taskDocRef = doc(db, "security", id);

    await deleteDoc(taskDocRef);

    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
