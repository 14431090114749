import React from "react";
import ReactApexChart from "react-apexcharts";
import "./Dashboard.css";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";




const OutpassChart = () => {
const [approvedOutpass,setApprovedOutpass]=useState([]);
const {outPassDetails}=useSelector(state=>state.outPass)

    useEffect(()=>{
        
       setApprovedOutpass( outPassDetails.filter((e)=>{
            return e.data.status == "approved" ?  e :"" 
        }))
    },[outPassDetails])

const options = {
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         width: 200,
    //       },
    //       legend: {
    //         position: "bottom",
    //       },
    //     },
    //   },
    // ],
    labels: [`${outPassDetails.length} Total`, `${approvedOutpass.length} Approved`],
    dataLabels: { enabled: true },
    tooltip: { enabled: false },
    stroke: {
        colors: ["#eef3fb"],
    },
    plotOptions: { pie: { donut: { size: "50%" } } },
    legend: { position: "bottom" },
};
    return (
        <div>
            <p className="chart-para">Outpass</p>
            <ReactApexChart
                options={options}
                series={[outPassDetails.length,approvedOutpass.length]}
                type="donut"
                width="300"
            />
        </div>
    );
};

export default OutpassChart;
