import React, { useEffect, useState } from "react";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import data_table from "./security_data_tale";
import "../Components/dataTable.css";
import { SecurityModal } from "./SecurityModal";
//firebase
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { GridColumnsPanel } from "@mui/x-data-grid";
import { timeCC } from "../helpers/timeConverter";
import { Box, Button, Link, Typography } from "@mui/material";


const SecurityDataTable = (props) => {
  const [security, setSecurity] = useState([]);
  const [show, setShow] = useState(false);
  const [securityData, setSecurityData] = useState([]);
  const{searchedName}=props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [outpass, setOutpass] = useState([]);
    //initialize datatable
    const dispatch=useDispatch();

    const {securityDetails}=useSelector(state=>state.security)
 

  //initialize datatable
  useEffect(() => {
   

    // $(document).ready(function () {
    //   $("#example").DataTable({
    //     bDestroy: true,
    //     lengthMenu: [7, 10],
    //     language: {
    //       sLengthMenu: "",
    //       search: "_INPUT_",
    //       searchPlaceholder: "Search by name...",
    //     },
    //     bInfo: false,
    //   });
    // });
  }, []);

  const empty = Array.from(document.getElementsByClassName("dataTables_empty"));
  empty.forEach((element) => {
    element.style.display = "none";
  });

  
    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleViewProfile=(index)=>{
    setShow(true);
    setSecurity(securityDetails[index])
  }

const items=[];
securityDetails.map((item,index)=>{
    items.push(
        {   id:item.id,
            name:item.data.name,
            srNo:index+1,
            index:index,
            mobileNumber:item.data.mobileNumber,
            gateNumber:item.data.gateNo,
            address:item.data.address,
            workingHours:item.data.workingHours,
            
        }
    )
})

const columns = [
    { id: "Srno", label: "Sr no.", minWidth: 70, align: "right" },
    { id: "name", label: "Name", minWidth: 70, align: "center" },
    {
      id: "mobileNumber",
      label: "Mobile Number",
      minWidth: 70,
      align: "center",
    },
    {
      id: "gateNumber",
      label: "Gate Number ",
      minWidth: 70,
      align: "center",
    },
  
    {
      id: "address",
      label: "Address",
      minWidth: 70,
      align: "center",
    },
    {
      id: "workingHours",
      label: "Working Hours",
      minWidth: 70,
      align: "center",
    },
    {
      id: "action",
      label: "Action",
      minWidth: 70,
      align: "center",
    },
    

  
  ];
  

  return (
    <div>
    <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ textAlign: "center" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" , backgroundColor :"rgb(164 208 241)"}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {searchedName ?<TableBody>
            {items.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).filter((item)=>item.name.toLowerCase().includes(searchedName.toLowerCase())).map((item) => {
              return (
                <TableRow hover tabIndex={-1} key={item.code}>
                  
                      <TableCell align="center" >
                      {item.srNo}
                      </TableCell>
                  
                      <TableCell align="center" >
                      {item.name}
                      </TableCell>
                  
                     
                  
                      <TableCell  align="center">
                      {item.mobileNumber}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.gateNumber}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.address}
                      </TableCell>
                      <TableCell  align="center">
                      {item.workingHours}
                      </TableCell>
                      <TableCell  align="center">
                  {<Button sx={{fontSize:"12px", textDecoration:"underline"}} onClick={()=>handleViewProfile(item.index)}>View Profile</Button>}
                  </TableCell>
                    
                    
                </TableRow>
              );
            })}
          </TableBody>:<TableBody>
            {items.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map((item) => {
              return (
                <TableRow hover tabIndex={-1} key={item.code}>
                  
                <TableCell align="center" >
                {item.srNo}
                </TableCell>
            
                <TableCell align="center" >
                {item.name}
                </TableCell>
            
               
            
                <TableCell  align="center">
                {item.mobileNumber}
                </TableCell>
            
                <TableCell  align="center">
                {item.gateNumber}
                </TableCell>
            
                <TableCell  align="center">
                {item.address}
                </TableCell>
                <TableCell  align="center">
                {item.workingHours}
                </TableCell>
                <TableCell  align="center">
            {<Button sx={{fontSize:"12px", textDecoration:"underline"}} onClick={()=>handleViewProfile(item.index)}>View Profile</Button>}
            </TableCell>
              
              
          </TableRow>
              );
            })}
          </TableBody> }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {show && (
        <SecurityModal
          show={show}
          setShow={setShow}
          securityData={security}
          setSecurityData={setSecurityData}
        />
      )}
    </div>
  );
};

export default SecurityDataTable;
