import React, { useState } from "react";
import Sidebar from "../Components/Sidebar";
import "./StudentProfile.css";
import Navbar from "../Components/Navbar";
import StudentAbsentChart from "./StudentAbsentChart";
import OutpassChart from "../Dashboard/OutpassChart";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { Col, Container, Form, Row, Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getWardenDetails } from "../apis/wardenApi";
import { Autocomplete, Input, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { setNewStudent } from "../store/studentsSlice";
import { setPage, setRecaptcha } from "../store/loginSlice";
import VerifyModal from "../Components/optValidationModal";
import { setUpRecaptcha } from "../helpers/setUpRecaptcha";

const StudentProfile = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [gurdiansEmail, setGurdiansEmail] = useState("");
  const [registeredNo, setRegisteredNo] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [gurdianName, setGurdianName] = useState("");
  const [gurdianMobile, setGurdianMobile] = useState("");
  const [course, setCourse] = useState("");
  const [outpassToken, setOutpassToken] = useState("");
  const [hostelAdmissionNo, setHostelAdmissionNo] = useState("");
  const [gurdianAddress, setGurdianAddress] = useState("");
  const [dob, setDob] = useState("");
  const [warden, setWarden] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [email, setEmail] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [mobileErr, setMobileErr] = useState(false);
  const [aadharErr, setAadharErr] = useState(false);
  const [gurdianNumErr, setGurdianNumErr] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [show, setShow] = useState(false);
  const { hostelDetails } = useSelector((state) => state.hostel);
  let hostelNameList = [];
  hostelDetails.map((hostel) => {
    hostel.data.hostelList.map((list) => {
      hostelNameList.push(list);
    });
  });
  // const [gaurdianOtpShow,setGaurdianOtpShow]=useState(false);
  // const [studentOtpShow,setStudentOtpShow]=useState(false);

  const handleMobNum = (e) => {
    setMobile(e.target.value);
    if (e.target.value.length === 10) {
      setMobileErr(false);
    } else {
      setMobileErr(true);
    }
  };
  const handleAadNum = (e) => {
    setAadhar(e.target.value);
    if (e.target.value.length === 12) {
      setAadharErr(false);
    } else {
      setAadharErr(true);
    }
  };
  const handleGuardianNum = (e) => {
    setGurdianMobile(e.target.value);
    if (e.target.value.length === 10) {
      setGurdianNumErr(false);
    } else {
      setGurdianNumErr(true);
    }
  };

  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        e.target.checkValidity() &&
        !mobileErr &&
        !aadharErr &&
        !gurdianNumErr
      ) {
        await verifyGauNumber();

        let number = "+91" + mobile;
        const response = await setUpRecaptcha(number);

        if (response !== undefined) {
          dispatch(setRecaptcha(response));
          dispatch(
            setNewStudent({
              name,
              gender,
              address,
              guardianEmail: gurdiansEmail,
              rollNumber: registeredNo,
              roomNumber,
              mobileNumber: mobile,
              gurdianName,
              gurdianMobile,
              course,
              outpassToken,
              hostelAdmNo: hostelAdmissionNo,
              gurdianAddress,
              dob,
              wardenName: warden,
              aadharNumber: aadhar,
              email,
              hostelName: hostelName,
              profileUrl:
                "https://firebasestorage.googleapis.com/v0/b/rgmcet-21ea4.appspot.com/o/profile.png?alt=media&token=010aec3d-1304-47dc-8045-09b477e2d231"
            })
          );
          dispatch(setPage("student"));

          setOtpShow(true);
        }
      } else {
        alert("Missing field !!!");
        return;
      }
    } catch (err) {
      alert(err.message);
      console.log(err);
    }
  };

  const verifyGauNumber = async () => {
    try {
      let number = "+91" + gurdianMobile;
      const response = await setUpRecaptcha(number);
      if (response !== undefined) {
        dispatch(setRecaptcha(response));
      }
      setOtpShow(true);
    } catch (err) {
      console.log(err);
      alert(err.message);
      setOtpShow(false);
    }
  };
  const { wardenDetails } = useSelector((state) => state.warden);

  let wardenNameList = [];
  wardenDetails.map((warden) => {
    wardenNameList.push(warden.data.name);
  });

  return (
    <>
      <div className="navmain">
        <Navbar />
      </div>
      <div className="wardenmain">
        <Container>
          <Row>
            <Col sm={12} lg={2}>
              <Sidebar />
            </Col>
            <Col sm={12} lg={10} className="mt-3">
              <Container>
                <Row>
                  <Col sm={12}>
                    <div className="heading">
                      <Link to="/home">Dashboard</Link> /{" "}
                      <span className="warden-span">&nbsp; StudentProfile</span>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <h2 style={{ fontFamily: "Inter" }}>Student Profile</h2>
                    <form className="row student-form" onSubmit={handleSubmit}>
                      <div className="col-lg-3 form-items">
                        <div>
                          <label style={{ fontFamily: "Inter" }}>Name</label>
                          <br />
                          <input
                            required
                            type="text"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>Gender</label>
                          <br />
                          <input
                            required
                            type="text"
                            value={gender}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Guardian's Email
                          </label>
                          <br />
                          <input
                            required
                            type="email"
                            value={gurdiansEmail}
                            onChange={(e) => {
                              setGurdiansEmail(e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>Address</label>
                          <br />
                          <textarea
                            required
                            value={address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 form-items">
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Registered No.
                          </label>
                          <br />
                          <input
                            required
                            type="text"
                            value={registeredNo}
                            onChange={(e) => {
                              setRegisteredNo(e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Room Number
                          </label>
                          <br />
                          <input
                            required
                            type="text"
                            value={roomNumber}
                            onChange={(e) => {
                              setRoomNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Hostel Name
                          </label>
                          <br />
                          {/* <input
                          required
                          type="text"
                          value={hostelName}
                          onChange={(e) => {
                            setHostelName(e.target.value);
                          }}
                        /> */}
                          <Autocomplete
                            sx={{
                              backgroundColor: "inherit"
                            }}
                            value={hostelName}
                            options={hostelNameList}
                            onChange={(event, newValue) =>
                              setHostelName(newValue)
                            }
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <input type="text" {...params.inputProps} />
                              </div>
                            )}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Mobile Number
                          </label>
                          <br />
                          <input
                            type="number"
                            style={{
                              border: `1px solid ${
                                mobileErr ? "red " : "white"
                              }`
                            }}
                            value={mobile}
                            onChange={(e) => handleMobNum(e)}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Guardian's Name
                          </label>
                          <br />
                          <input
                            required
                            type="text"
                            value={gurdianName}
                            onChange={(e) => {
                              setGurdianName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 form-items">
                        <div>
                          <label style={{ fontFamily: "Inter" }}>Course</label>
                          <br />
                          <input
                            required
                            type="text"
                            value={course}
                            onChange={(e) => {
                              setCourse(e.target.value);
                            }}
                          />
                        </div>
                        {/* <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Outpass Token
                        </label>
                        <br />
                        <input
                          required
                          type="text"
                          value={outpassToken}
                          onChange={(e) => {
                            setOutpassToken(e.target.value);
                          }}
                        />
                      </div> */}
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Hostel Admission No.
                          </label>
                          <br />
                          <input
                            required
                            type="text"
                            value={hostelAdmissionNo}
                            onChange={(e) => {
                              setHostelAdmissionNo(e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Guardian's Mobile Number
                          </label>
                          <br />
                          <input
                            style={{
                              border: `1px solid ${
                                gurdianNumErr ? "red " : "white"
                              }`
                            }}
                            type="number"
                            value={gurdianMobile}
                            onChange={(e) => handleGuardianNum(e)}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Guardian's Address
                          </label>
                          <br />
                          <textarea
                            required
                            value={gurdianAddress}
                            onChange={(e) => {
                              setGurdianAddress(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 form-items">
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Date of Birth
                          </label>
                          <br />
                          <input
                            required
                            type="text"
                            value={dob}
                            onChange={(e) => {
                              setDob(e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Warden Name
                          </label>
                          <br />

                          <Autocomplete
                            sx={{ backgroundColor: "inherit" }}
                            value={warden}
                            options={wardenNameList}
                            onChange={(event, newValue) => setWarden(newValue)}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <input type="text" {...params.inputProps} />
                              </div>
                            )}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>
                            Aadhaar Number
                          </label>
                          <br />
                          <input
                            style={{
                              border: `1px solid ${
                                aadharErr ? "red " : "white"
                              }`
                            }}
                            type="number"
                            value={aadhar}
                            onChange={(e) => handleAadNum(e)}
                          />
                        </div>
                        <div>
                          <label style={{ fontFamily: "Inter" }}>Email</label>
                          <br />
                          <input
                            required
                            type="email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div id="recaptcha-container"></div>
                      <div className="buttons">
                        <button className="btn btn-primary">Add New</button>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            navigate("/student");
                          }}
                        >
                          Go Back
                        </button>
                      </div>
                    </form>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Modal show={otpShow} onHide={() => setOtpShow(false)}>
          <ModalBody>
            <VerifyModal
              setShow={(val) => setShow(val)}
              setOtpShow={(val) => setOtpShow(val)}
            />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default StudentProfile;
