import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { getStudents } from "../store/studentsSlice";

export const getStudentsDetails = async (dispatch, payload) => {
  try {
    const q = query(collection(db, "students"));
    await onSnapshot(q, (querySnapshot) => {
      dispatch(
        getStudents(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
    });

    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
export const addNewStudent = async (dispatch, { newStudent, uuId }) => {
  try {
    const usersRef = doc(db, "students", uuId);

    const docSnap = await getDoc(usersRef);

    if (docSnap.exists()) {
      alert("Number Already Exist !!!");
    } else {
      const res = await setDoc(doc(db, "students", uuId), { ...newStudent });
      await getStudentsDetails(dispatch);
      return;
    }
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};

export const updateStudentMobile = async (
  dispatch,
  { updateStudent, uuId, currentStudentId }
) => {
  try {
    const usersRef = doc(db, "students", uuId);

    const docSnap = await getDoc(usersRef);

    if (docSnap.exists()) {
      alert("Number Already Exist !!!");
    } else {
      const res = await setDoc(doc(db, "students", uuId), {
        ...updateStudent,
      });
      await getStudentsDetails(dispatch);
      await deleteStudent(currentStudentId);
    }

    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};

export const deleteStudent = async (id) => {
  try {
    const taskDocRef = doc(db, "students", id);

    await deleteDoc(taskDocRef);

    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
