import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./optvalidation.css";
import img from "../img.png";
import OtpInput from "react-otp-input";
import generateToken from "../helpers/generateToken";
import { useDispatch, useSelector } from "react-redux";
import { setPage, setUuId, setUuid } from "../store/loginSlice";
import {
  addSecurity,
  deleteSecurity,
  updateSecurityMobile,
} from "../apis/securityApi";
import { addWarden, deleteWarden, updateWardenMobile } from "../apis/wardenApi";
import { useEffect } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { updateNewSecurity } from "../store/securitySlice";
import { addNewWarden } from "../store/wardenSlice";
import {
  addNewStudent,
  deleteStudent,
  updateStudentMobile,
} from "../apis/studentApi";

const VerifyModal = (props) => {
  const { setOtpShow, setShow } = props;
  const navigate = useNavigate();
  // const { state } = useLocation();
  const [otp, setOtp] = useState("");
  const { loginDetails, uuId, objj, page } = useSelector(
    (state) => state.login
  );
  const { newSecurity, updateSecurity, currentSecurityId } = useSelector(
    (state) => state.security
  );
  const { newStudent, updateStudent, currentStudentId } = useSelector(
    (state) => state.students
  );
  const { newWarden, updateWarden, currentWardenId } = useSelector(
    (state) => state.warden
  );

  //   const objj = { ...obj };

  const dispatch = useDispatch();

  let repage = page != "" ? `${page}` : "home";
  const verifyOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await objj.confirm(otp);

      dispatch(setUuId(response.user.uid));
      switch (page) {
        case "security":
          await addSecurity(dispatch, { newSecurity, uuId: response.user.uid });
          navigate(`/${repage}`, {
            state: { uid: response.user.uid },
          });
          break;

        case "warden":
          await addWarden(dispatch, {
            newWarden: newWarden,
            uuId: response.user.uid,
          });
          navigate(`/${repage}`, {
            state: { uid: response.user.uid },
          });
          break;

        case "student":
          await addNewStudent(dispatch, {
            newStudent: newStudent,
            uuId: response.user.uid,
          });
          navigate(`/${repage}`, {
            state: { uid: response.user.uid },
          });
          break;
        case "securityModal":
          console.log("Bro");
          await updateSecurityMobile(dispatch, {
            updateSecurity: updateSecurity,
            uuId: response.user.uid,
            currentSecurityId: currentSecurityId,
          });
          break;
        case "wardenModal":
          await updateWardenMobile(dispatch, {
            updateWarden: updateWarden,
            uuId: response.user.uid,
            currentWardenId: currentWardenId,
          });

          break;
        case "studentModal":
          await updateStudentMobile(dispatch, {
            updateStudent: updateStudent,
            uuId: response.user.uid,
            currentStudentId: currentStudentId,
          });

          break;

        default:
          break;
      }

      const token = await generateToken(loginDetails[0].id);
      localStorage.setItem("token", token);
      setOtpShow(false);
      setShow(false);
    } catch (err) {
      alert(err.message);
      // setOtpShow(false);
      console.log(err);
      document.getElementById("error").style.display = "block";
    }
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  return (
    <div className="modalVali-main">
      <div className="modalVali-image">
        <img src={img} alt="img" />
      </div>
      <div className="modalVali-welcome">
        <h2>Verification</h2>
      </div>

      <form className="modalVali-form">
        <h3 className="title">Verify OTP</h3>
        <p className="subtitle">Enter the OTP sent to your Mobile Number</p>
        <br></br>
        <div className="">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={<span>&nbsp;&nbsp;&nbsp;</span>}
            inputStyle={{ width: "25px" }}
          />
        </div>
        <button className="modalVali-submit" onClick={verifyOTP}>
          Verify
        </button>
        <div id="error" style={{ display: "none", margin: "1rem" }}>
          Please enter correct OTP
        </div>
      </form>
    </div>
  );
};

export default VerifyModal;
