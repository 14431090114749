import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { getWarden } from "../store/wardenSlice";

export const getWardenDetails = async (dispatch, payload) => {
  try {
    const q = query(collection(db, "warden"));
    await onSnapshot(q, (querySnapshot) => {
      dispatch(
        getWarden(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
    });

    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};

export const addWarden = async (dispatch, { newWarden, uuId }) => {
  try {
    const usersRef = doc(db, "warden", uuId);

    const docSnap = await getDoc(usersRef);

    if (docSnap.exists()) {
      alert("Number Already Exist !!!");
    } else {
      const res = await setDoc(doc(db, "warden", uuId), { ...newWarden });

      await getWardenDetails(dispatch);

      return;
    }
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};

export const updateWardenMobile = async (
  dispatch,
  { updateWarden, uuId, currentWardenId }
) => {
  try {
    const usersRef = doc(db, "warden", uuId);

    const docSnap = await getDoc(usersRef);

    if (docSnap.exists()) {
      alert("Number Already Exist !!!");
    } else {
      const res = await setDoc(doc(db, "warden", uuId), {
        ...updateWarden,
      });
    }
    await getWardenDetails(dispatch);
    await deleteWarden(currentWardenId);

    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};

export const deleteWarden = async (id) => {
  try {
    const taskDocRef = doc(db, "warden", id);

    await deleteDoc(taskDocRef);

    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
