import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export function timeCC(duration) {
  // var milliseconds = parseInt((duration % 1000) / 100),
  //   seconds = Math.floor((duration / 1000) % 60),
  //   minutes = Math.floor((duration / (1000 * 60)) % 60),
  //   hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  // hours = hours < 10 ? "0" + hours : hours;
  // minutes = minutes < 10 ? "0" + minutes : minutes;
  // seconds = seconds < 10 ? "0" + seconds : seconds;

  // return hours + ":" + minutes;
  var date = new Date(duration).toString()
  date = date.split(" ")[4]
  var hours = date.split(":")[0]
  var minutes = date.split(":")[1]

  return hours + ":" + minutes;
}

export default function timeC(epoch_time, timeZone) {
  let date_obj = new Date(Number(epoch_time));
  //error handling if no timezone is sent
  if (timeZone !== undefined && timeZone !== "") {
    date_obj = dayjs(date_obj).tz(timeZone);
  } else {
    date_obj = dayjs(date_obj);
  }

  const hrs = date_obj.hour();
  const mins = date_obj.minute();
  let hhmm =
    (hrs < 10 ? "0" + hrs : hrs) + ":" + (mins < 10 ? "0" + mins : mins);

  return hhmm;
}

export function DateCon(mili) {
  // let date = new Date(mili);
  // let d = date.toString().split("T");
  // return d[0];
  var date = new Date(mili); // Date 2011-05-09T06:08:45.178Z
  var year = date.getFullYear();
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  return (`${year}-${month}-${day}`);
}
