import { configureStore } from "@reduxjs/toolkit";
import attendanceReducer from "./attendanceSlice";
import studentsReducer from "./studentsSlice";
import outPassReducer from "./outPassSlice";
import wardenReducer from "./wardenSlice";
import securityReducer from "./securitySlice";
import loginReducer from "./loginSlice";
import hostelReducer from "./hostelSlice";
import { Iterable } from "immutable";
import {
  createSerializableStateInvariantMiddleware,
  isPlain,
} from "@reduxjs/toolkit";
import { hostelSlice } from "./hostelSlice";

// Augment middleware to consider Immutable.JS iterables serializable
const isSerializable = (value) => Iterable.isIterable(value) || isPlain(value);

const getEntries = (value) =>
  Iterable.isIterable(value) ? value.entries() : Object.entries(value);

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable,
  getEntries,
});

export default configureStore({
  reducer: {
    students: studentsReducer,
    attendance: attendanceReducer,
    outPass: outPassReducer,
    warden: wardenReducer,
    security: securityReducer,
    login: loginReducer,
    hostel: hostelReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["your/action/type"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        // Ignore these paths in the state
        ignoredPaths: ["items.dates"],
      },
    }),
});
