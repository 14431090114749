import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Chart from "react-apexcharts";
import { collection, query, onSnapshot } from "firebase/firestore";
import "./Dashboard.css";
import { db } from "../firebase";

const series = [120, 100, 20];

const AttendanceChart = ({}) => {
    const [std, setStudents] = useState([]);
    const [obj, setObj] = useState({ total: 0, present: 0, absent: 0 });
    async function fetchData() {
        const q = query(collection(db, "attendence"));
        await onSnapshot(q, (querySnapshot) => {
            setStudents(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }
    useEffect(() => {
        fetchData();
        let data = [];
        std.map((o) => {
            data.push(o.data.attendence[0], o.data.attendence[1]);
        });
        let total = data.length;
        let present = 0;
        let absent = 0;
        data.map((o) => {
            if (o.isPresent) {
                present++;
            } else {
                absent++;
            }
        });
        setObj({
            total,
            present,
            absent,
        });
    }, []);

    return (
        <div>
            <p className="chart-para">Present and Absent</p>
            {std && obj ? (
                <Chart
                    options={{
                        labels: ["total", "present", "absent"],
                        tooltip: { enabled: false },
                        dataLabels: { enabled: true },
                        stroke: {
                            colors: ["#eef3fb"],
                        },
                        plotOptions: {
                            pie: { donut: { size: "60%" } },
                            expandOnClick: false,
                        },
                        legend: { position: "bottom" },
                    }}
                    series={[8, 4, 4]}
                    type="donut"
                    width="300"
                />
            ) : (
                ""
            )}
        </div>
    );
};

export default AttendanceChart;
