import { createSlice } from "@reduxjs/toolkit";

export const securitySlice = createSlice({
  name: "security",
  initialState: {
    securityDetails: [],
    newSecurity: [],
    updateSecurity: [],
    currentSecurityId: "",
  },

  reducers: {
    getSecurity: (state, { payload }) => {
      state.securityDetails = payload;
    },
    addNewSecurity: (state, { payload }) => {
      state.newSecurity = payload;
    },
    updateNewSecurity: (state, { payload }) => {
      state.updateSecurity = payload;
    },
    setCurrentSecurityId: (state, { payload }) => {
      state.currentSecurityId = payload;
    },
  },
});

export const {
  getSecurity,
  addNewSecurity,
  updateNewSecurity,
  setCurrentSecurityId,
} = securitySlice.actions;

export default securitySlice.reducer;
