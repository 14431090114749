import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Login.css";
import img from "../img.png";
import OtpInput from "react-otp-input";
import generateToken from "../helpers/generateToken";
import { useDispatch, useSelector } from "react-redux";

const Verify = () => {
  const navigate = useNavigate();
  // const { state } = useLocation();
  const [otp, setOtp] = useState("");
  const { loginDetails, uuId, page, objj } = useSelector(
    (state) => state.login
  );
  const { newSecurity } = useSelector((state) => state.security);

  const dispatch = useDispatch();

  let repage = page != "" ? `${page}` : "home";

  const verifyOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await objj.confirm(otp);

      // switch (page) {
      //   case "security":
      //     dispatch(setUuId(response.user.uid));
      //     addSecurity(dispatch, { newSecurity, uuId: response.user.uid });
      //     break;

      //   case "warden":
      //     await addNewWarden(newSecurity, { uuId: response.user.uid});
      //     break;

      //   case "student":
      //     await addNewStudent(newSecurity, { uuId: response.user.uid});
      //     break;

      //   default:
      //     break;
      // }
      const token = await generateToken(loginDetails[0].id);
      localStorage.setItem(
        "token",
        "09ABhfS3Fn8fIBG0yMuUhNXNDq2Pjd0-NNFB_d6o51grYhaFLD9NZ3wMyCfgLwYdLyiSdGGiiNzJy6Sf1mN9qgAP08AA6OGeuHsvc"
      );

      navigate(`/${repage}`, {
        state: { uid: "response.user.uid" },
      });
    } catch (err) {
      console.log(err);
      document.getElementById("error").style.display = "block";
    }
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  return (
    <div className="main">
      <div className="image">
        <img src={img} alt="img" />
      </div>
      <div className="welcome">
        <h2>Verification</h2>
      </div>

      <form className="form">
        <h3 className="title">Verify OTP</h3>
        <p className="subtitle">Enter the OTP sent to your Mobile Number</p>
        <br></br>
        <div>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={<span>&nbsp;&nbsp;&nbsp;</span>}
            inputStyle={{ width: "30px" }}
          />
        </div>
        <button className="submit" onClick={verifyOTP}>
          Verify
        </button>
        <div id="error" style={{ display: "none", margin: "1rem" }}>
          Please enter correct OTP
        </div>
      </form>
    </div>
  );
};

export default Verify;
