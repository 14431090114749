import {
  getAuth,
  signInWithPhoneNumber,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
} from "firebase/auth";

export function setUpRecaptcha(number) {
  const auth = getAuth();

  let verify = new RecaptchaVerifier("recaptcha-container", {}, auth);
  verify.render();
  const res = signInWithPhoneNumber(auth, number, verify);
  return res;
}

export function setUpRecaptchaWithEmail(email, password) {
  const auth = getAuth();

  console.log(email, password);

  // let verify = new RecaptchaVerifier("recaptcha-container", {}, auth);
  // verify.render();
  const res = createUserWithEmailAndPassword(auth, email, password);
  return res;
}
