import { createSlice } from "@reduxjs/toolkit";

export const studentsSlice = createSlice({
  name: "students",
  initialState: {
    studentsDetails: [],
    newStudent: [],
    updateStudent: [],
    currentStudentId: "",
  },
  reducers: {
    getStudents: (state, { payload }) => {
      state.studentsDetails = payload;
    },
    setNewStudent: (state, { payload }) => {
      state.newStudent = payload;
    },
    setCurrentStudentId: (state, { payload }) => {
      state.currentStudentId = payload;
    },
    setUpdateStudent: (state, { payload }) => {
      state.updateStudent = payload;
    },
  },
});

export const {
  getStudents,
  setNewStudent,
  setCurrentStudentId,
  setUpdateStudent,
} = studentsSlice.actions;

export default studentsSlice.reducer;
