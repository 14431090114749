import React, { useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import img from "../Dashboard/warden-demo.png";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Box, Input, Typography } from "@mui/material";
import VerifyModal from "../Components/optValidationModal";
import { useDispatch } from "react-redux";
import { setPage, setRecaptcha } from "../store/loginSlice";
import { addNewSecurity, setCurrentSecurityId, updateNewSecurity } from "../store/securitySlice";
import { setUpRecaptcha } from "../helpers/setUpRecaptcha";
import { deleteSecurity } from "../apis/securityApi";

export const SecurityModal = (props) => {
  const { securityData, show, setShow, setSecurityData } = props;
  const data = securityData.data;
  const id = securityData.id;

  const [name, setName] = useState(data.name);
  const [address, setAddress] = useState(data.address);
  const [dob, setDob] = useState(data.dob);
  const [mobile, setMobile] = useState(data.mobileNumber);
  const [gateNo, setGateNo] = useState(data.gateNo);
  const [gender, setGender] = useState(data.gender);
  const [aadhar, setAadhar] = useState(data.aadharNumber);
  const [delConfirm,setDelConfirm]=useState(false);
  const [mobileErr,setMobileErr]=useState(false);
  const [aadharErr,setAadharErr]=useState(false);
  const [otpShow,setOtpShow]=useState(false);

  const handleMobNum=(e)=>{
    setMobile(e.target.value);
    if(e.target.value.length===10){
      setMobileErr(false); 
      
    }
    else{
      setMobileErr(true);
    }
  }
  
  const handleAadNum=(e)=>{  
    setAadhar(e.target.value); 
    if(e.target.value.length===12){
      setAadharErr(false)
        }else{
          setAadharErr(true)
        }
      

  }

  const deleteHandler = async () => {
    await deleteSecurity(id)
    setShow(false);
    setSecurityData([]);
  };
const dispatch=useDispatch();

  const updateHandler = async (e) => {
    e.preventDefault();
   
    if(e.target.checkValidity() && !mobileErr && !aadharErr ){
    const taskDocRef = doc(db, "security",id );
    try {
    if(mobile !==data.mobileNumber){
     await changeMobileNumber();
      return;
    }  
     await updateDoc(taskDocRef, {
        name,
        address,
        dob,
        mobileNumber: mobile,
        gateNo,
        gender,
        aadharNumber: aadhar,
      });
    } catch (err) {
      console.log(err);
    }
    setShow(false);
    setSecurityData([]);}else{
      alert("Missing Fields !!!")
    }
  };
  const changeMobileNumber=async()=>{
    try{
      let number = "+91" + mobile;
      const response = await setUpRecaptcha(number);
      if (response !== undefined) {
       
       dispatch(setRecaptcha(response))
 
       dispatch(updateNewSecurity({name,
         address,
         dob,
         mobileNumber: mobile,
         gateNo,
         gender,
         aadharNumber: aadhar,}))
       dispatch(setPage("securityModal"))
       dispatch(setCurrentSecurityId(id))
    
     setOtpShow(true)
     
    }
  }catch(err){
    console.log(err)
    alert(err.message)
    setOtpShow(false)
    setShow(false)
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header>
          <img
            src={img}
            alt=""
            height="30px"
            width="30px"
            style={{ borderRadius: "2rem", margin: "0 5px" }}
          />
          <span style={{ fontFamily: "Inter" }}>Security Profile</span>
        </Modal.Header>
        <Modal.Body>
          <form className="row warden-add-form">
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Name</label>
              <br />
              <input
                required
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 small-inputs">
              <label style={{ fontFamily: "Inter" }}>Date of Birth</label>
              <br />
              <input
                required
                type="text"
                value={dob}
                onChange={(e) => {
                  setDob(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 small-inputs">
              <label style={{ fontFamily: "Inter" }}>Gender</label>
              <br />
              <input
                required
                type="text"
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Aadhar Number</label>
              <br />
              <input
                        style={{border:`1px solid ${aadharErr ? "red ": "white"}`}}
                        type="number"
                        value={aadhar}
                        onChange={(e)=>handleAadNum(e)}
                        />
            </div>
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Mobile Number</label>
              <br />
              <input
                        style={{border:`1px solid ${mobileErr ? "red ": "white"}`}}
                        type="number"
                        value={mobile}
                        onChange={(e)=>handleMobNum(e)}
                        />
              <div id="recaptcha-container"></div>
            </div>
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Gate Number</label>
              <br />
              <input
                required
                type="text"
                value={gateNo}
                onChange={(e) => {
                  setGateNo(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Address</label>
              <br />
              <textarea
                required
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer-buttons">
            <Button style={{ fontFamily: "Inter" }} onClick={updateHandler}>
              Update
            </Button>
            <Button
              style={{ fontFamily: "Inter" }}
              className="btn btn-danger"
              onClick={()=>setDelConfirm(true)}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={delConfirm}
        onHide={() => {
          setDelConfirm(false);
        }}>
        <ModalBody>

        < Box sx={{display:"flex",flexDirection:"column", alignContent:"center",justifyContent:"center", }}><Typography variant="h6" sx={{margin:"auto",fontFamily:"Inter",mb:2}}>Confirm Delete</Typography>
        <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
        <Button style={{ fontFamily: "Inter",marginRight:"2rem" }}
              className="btn btn-danger" onClick={deleteHandler}>Delete</Button>
        <Button style={{ fontFamily: "Inter" }} onClick={()=>setDelConfirm(false)}>Cancel</Button></Box>

        </Box>
        </ModalBody>
        </Modal>
        <Modal show={otpShow}  onHide={()=>(setOtpShow(false))}>
          <ModalBody >
        <VerifyModal setShow={(val)=>setShow(val)} setOtpShow={(val)=>setOtpShow(val)}/>
          </ModalBody>
        </Modal>
    </>
  );
};
