import React, { useEffect, useState } from "react";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
// import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import $ from "jquery";
import "../Components/dataTable.css";
//firebase
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import StudentModal from "./Student_Modal";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { GridColumnsPanel } from "@mui/x-data-grid";
import { timeCC } from "../helpers/timeConverter";
import { Box, Button, Link, Typography } from "@mui/material";

const StudentDataTable = (props) => {
  const [students, setStudent] = useState([]);
  const [show, setShow] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [outpass, setOutpass] = useState([]);
  //initialize datatable
  const dispatch=useDispatch();

  const {studentsDetails}=useSelector(state=>state.students)
  

  //initialize datatable
  useEffect(() => {
    // $(document).ready(function () {
    //   $("#example").DataTable({
    //     bDestroy: true,
    //     lengthMenu: [7, 10],
    //     language: {
    //       sLengthMenu: "",
    //       search: "_INPUT_",
    //       searchPlaceholder: "Search by name...",
    //     },
    //     bInfo: false,
    //   });
    // });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleViewProfile=(index)=>{
    setShow(true);
    setStudent(studentsDetails[index])
  }
const items=[];
studentsDetails.map((item,index)=>{
    items.push(
        {   id:item.id,
          index:index,
          srNo:index+1,
            name:item.data.name,
           registrationNo:item.data.rollNumber,
           hostelName:item.data.hostelName,
           hostelAdmissionNo:item.data.hostelAdmNo,
           roomNo:item.data.roomNumber,
           isApproved:item.data.isApproved==true ? "Approved":"Not Approved"

        }
    )
})

const columns = [
    { id: "srNo", label: "Sr no.", minWidth: 70, align: "right" },
    { id: "name", label: "Name", minWidth: 70, align: "center" },
    {
      id: "registrationNo",
      label: "Registration No",
      minWidth: 70,
      align: "center",
    },
    {
      id: "isApproved",
      label: "Status",
      minWidth: 70,
      align: "center",
    },
    {
      id: "hostelName",
      label: "Hostel Name",
      minWidth: 70,
      align: "center",
    },
  
    {
      id: "hostelAdmNo",
      label: "Hostel Admission No",
      minWidth: 70,
      align: "center",
    },
    {
      id: "roomNo",
      label: "Room No",
      minWidth: 70,
      align: "center",
    },
    {
      id: "profile",
      label: "Profile",
      minWidth: 100,
      align: "center",
    },

  
  ];

  // const empty = Array.from(document.getElementsByClassName("dataTables_empty"));
  // empty.forEach((element) => {
  //   element.style.display = "none";
  // });

  const { searchedName } = props;

  return (
    <div>
       <TableContainer sx={{ maxHeight: 520 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ textAlign: "center" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" , backgroundColor :"rgb(164 208 241)"}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {searchedName ?<TableBody>
            {items.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).filter((item)=>item.name.toLowerCase().includes(searchedName.toLowerCase())).map((item) => {
              return (
                <TableRow hover tabIndex={-1} key={item.code}>
                  
                      <TableCell align="center" >
                      {item.srNo}
                      </TableCell>
                  
                      <TableCell align="center" >
                      {item.name}
                      </TableCell>

                      <TableCell  align="center">
                      {item.registrationNo}
                      </TableCell>
                      <TableCell  align="center">
                      <label
                            // onClick={() => handleAttendance(item)}
                            // variant="contained"
                            // align="right"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: `${
                                item.isApproved =="Approved"
                                  ? "#1eba1b"
                                  : "rgb(221 13 13)"
                              }`,
                              color: "white",
                              fontSize: "12px",
                              width: "6rem",
                              height: "2.1rem",
                              borderRadius: "7px",
                              border: "0px",
                            }}
                          >
                            {item.isApproved}
                          </label>
                      </TableCell>
                      <TableCell  align="center">
                      {item.hostelName}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.hostelAdmissionNo}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.roomNo}
                      </TableCell>
                      <TableCell  align="center">
                  {<Button sx={{fontSize:"9px", textDecoration:"underline",width:"14px"}} onClick={()=>handleViewProfile(item.index)}>View Profile</Button>}
                  </TableCell>
                    
                
                    
                </TableRow>
              );
            })}
          </TableBody>:<TableBody>
            {items.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map((item) => {
              return (
                <TableRow hover tabIndex={-1} key={item.code}>
                  
                  <TableCell align="center" >
                      {item.srNo}
                      </TableCell>
                  
                      <TableCell align="center" >
                      {item.name}
                      </TableCell>

                      <TableCell  align="center">
                      {item.registrationNo}
                      </TableCell>
                      <TableCell  align="center">
                      <label
                            // onClick={() => handleAttendance(item)}
                            // variant="contained"
                            // align="right"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: `${
                                item.isApproved =="Approved"
                                  ? "#1eba1b"
                                  : "rgb(221 13 13)"
                              }`,
                              color: "white",
                              fontSize: "12px",
                              width: "6rem",
                              height: "2.1rem",
                              borderRadius: "7px",
                              border: "0px",
                            }}
                          >
                            {item.isApproved}
                          </label>
                      </TableCell>
                      <TableCell  align="center">
                      {item.hostelName}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.hostelAdmissionNo}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.roomNo}
                      </TableCell>
                      <TableCell >
                  {<Button sx={{fontSize:"9px", textDecoration:"underline",width:"14px"}} onClick={()=>handleViewProfile(item.index)}>View Profile</Button>}
                  </TableCell>
                    
               

                    
                </TableRow>
              );
            })}
          </TableBody> }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {show && (
        <StudentModal
          show={show}
          setShow={setShow}
          studentData={students}
          setStudentData={setStudentData}
        />
      )}
    </div>
  );
};

export default StudentDataTable;
