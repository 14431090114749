import { createSlice } from "@reduxjs/toolkit";
export const loginSlice = createSlice({
  name: "login",
  initialState: {
    loginDetails: [],
    objj: {},
    uuId: "",
    page: "",
  },

  reducers: {
    setLogin: (state, { payload }) => {
      state.loginDetails = payload;
    },
    updateLogin: (state, { payload }) => {
      state.loginDetails = { ...state, payload };
    },
    setRecaptcha: (state, { payload }) => {
      state.objj = payload;
    },
    setUuId: (state, { payload }) => {
      state.uuId = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
  },
});

export const {
  setLogin,
  updateLogin,
  setRecaptcha,
  setUuId,
  setPage,
} = loginSlice.actions;

export default loginSlice.reducer;
