import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";
import generateToken from "../helpers/generateToken";
import { setLogin } from "../store/loginSlice";

export const login = async (dispatch, payload) => {
  try {
    const q = query(collection(db, "admin"));
    await onSnapshot(q, (snapshot) => {
      dispatch(
        setLogin(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
    });
    const token=generateToken()
    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
