import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { getHostel } from "../store/hostelSlice";

export const getHostelDetails = async (dispatch, payload) => {
  try {
    const q = query(collection(db, "hostels"));
    await onSnapshot(q, (querySnapshot) => {
      dispatch(
        getHostel(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
    });

    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
