const data_table = [
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    MobileNumber: "9874563210",
    GateNumber: "Gate 02",
    Address: "Lorem ipsum",
    WorkingHours: "10:00AM to 4:00AM",
  },
];

module.exports = data_table;
