import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import "../Warden/Warden.css";
import StudentDataTable from "./Student_DataTable";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Icon
import { MagnifyingGlass } from "phosphor-react";
import { useSelector } from "react-redux";
import { CircularProgress, Paper } from "@mui/material";

const Student = () => {
  // Search input state
  const [searchInput, setSearchInput] = useState("");

  const searchInputHandler = (e) => {
    setSearchInput(e.target.value);
  };
  const { studentsDetails } = useSelector((state) => state.students);

  return (
    <>
      <div className="navmain">
        <Navbar />
      </div>
      <div className="wardenmain">
        <Container>
          <Row>
            <Col sm={12} lg={2}>
              <Sidebar />
            </Col>
            {studentsDetails.length == 0 ? (
              <Paper
                sx={{
                  backgroundColor: "inherit",
                  mt: 50,
                  height: "inherit",
                  width: "100%",
                  display: "flex",
                  alignContent: "center",
                  alignItem: "center",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Paper>
            ) : (
              <Col sm={12} lg={10} className="mt-3">
                <Container>
                  <Row>
                    <Col sm={12}>
                      <div className="heading">
                        <Link to="/home">Dashboard</Link> /{" "}
                        <span className="warden-span">&nbsp; Students</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className="d-flex gap-2">
                      <InputGroup>
                        <InputGroup.Text id="basic-addon2">
                          <MagnifyingGlass size={25} color="#5c94cf" />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={searchInputHandler}
                          placeholder="Search by name"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                      </InputGroup>
                      <Link to="/studentProfile" className="button add-button">
                        + Add New
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      {!searchInput ? (
                        <StudentDataTable />
                      ) : (
                        <StudentDataTable searchedName={searchInput} />
                      )}
                    </Col>
                  </Row>
                </Container>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Student;
