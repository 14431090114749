import React, { useEffect, useState } from "react"
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"
import "jquery/dist/jquery.min.js"
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from "jquery"
import data_table from "./warden_data_table"
import "../Components/dataTable.css"
//firebase
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import WardenModal from "./WardenModal";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { GridColumnsPanel } from "@mui/x-data-grid";
import { timeCC } from "../helpers/timeConverter";
import { Box, Button, ButtonBase, Link, Typography } from "@mui/material";
import WardenPinModel from "./WardenPinModel"

export const WardenDataTable = (props) => {
  const [warden, setWarden] = useState([]);
  const [show, setShow] = useState(false);
  const [updatePinShow, setUpdatePinShow] = useState(false);
  const [wardenData, setWardenData] = useState([]);

  const { searchedName } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [outpass, setOutpass] = useState([]);
  //initialize datatable
  const dispatch=useDispatch();

  const {wardenDetails}=useSelector(state=>state.warden)


  //initialize datatable
  useEffect(() => {

    $(document).ready(function () {
      $("#example1").DataTable({
        dom: "lBrtiBp",
        bDestroy: true,
        lengthMenu: [7, 10],
        language: {
          sLengthMenu: "",
          search: "_INPUT_",
          searchPlaceholder: "Search by name...",
        },
        bInfo: false,
      });
    });
  }, []);

  const empty = Array.from(document.getElementsByClassName("dataTables_empty"));
  empty.forEach((element) => {
    element.style.display = "none";
  });
    
  const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleViewProfile=(index)=>{
      setShow(true);
      setWarden(wardenDetails[index])
    }

    const handleUpdatePin=(index)=>{
      setUpdatePinShow(true);
      setWarden(wardenDetails[index])
    }

  const items=[];
  wardenDetails.map((item,index)=>{
      items.push(
          {   id:item.id,
            index:index,
            srNo:index+1,
              name:item.data.name,
              hostelName:item.data.hostelName,
              blockName:item.data.blockName,
              blockNumber:item.data.blockNumber,
          }
      )
      
  })
  // setWardenData({items})

  const columns = [
      { id: "srNo", label: "Sr no.", minWidth: 70, align: "right" },
      { id: "name", label: "Name", minWidth: 70, align: "center" },
      {
        id: "hostelName",
        label: "Hostel",
        minWidth: 70,
        align: "center",
      },
      {
        id: "blockName",
        label: "Block Name",
        minWidth: 70,
        align: "center",
      },
    
      {
        id: "blockNumber",
        label: "blockNumber",
        minWidth: 70,
        align: "center",
      },
      {
        id: "profile",
        label: "Profile",
        minWidth: 70,
        align: "center",
      },
      

    
    ];
   
  return (
    <div>
     <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ textAlign: "center" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" , backgroundColor :"rgb(164 208 241)"}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {searchedName ?<TableBody>
            {items.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).filter((item)=>item.name.toLowerCase().includes(searchedName.toLowerCase())).map((item) => {
              return (
                  
                  <TableRow hover tabIndex={-1} key={item.code}>
                  
                  <TableCell align="center" >
                  {item.srNo}
                  </TableCell>
              
                  <TableCell align="center" >
                  {item.name}
                  </TableCell>
                  
              
                  <TableCell  align="center">
                  {item.hostelName}
                  </TableCell>
              
                  <TableCell  align="center">
                  {item.blockName}
                  </TableCell>
              
                  <TableCell  align="center">
                  {item.blockNumber}
                  </TableCell>
                  <TableCell  align="center">
                  {<Button sx={{fontSize:"12px", textDecoration:"underline"}} onClick={()=>handleViewProfile(item.index)}>View Profile</Button>}
                  {<Button sx={{fontSize:"12px", textDecoration:"underline"}} onClick={()=>{handleUpdatePin(item.index)}}>Update Pin</Button>}
                  </TableCell>
                    
                </TableRow>
              );
            })}
          </TableBody>:<TableBody>
            {items.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map((item) => {
              return (
                <TableRow hover tabIndex={-1} key={item.code}>
                  
                      <TableCell align="center" >
                      {item.srNo}
                      </TableCell>
                  
                      <TableCell align="center" >
                      {item.name}
                      </TableCell>
                      
                  
                      <TableCell  align="center">
                      {item.hostelName}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.blockName}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.blockNumber}
                      </TableCell>
                      <TableCell  align="center">
                      {<Button sx={{fontSize:"12px", textDecoration:"underline"}} onClick={()=>{handleViewProfile(item.index)}}>View Profile</Button>}
                      {<Button sx={{fontSize:"12px", textDecoration:"underline"}} onClick={()=>{handleUpdatePin(item.index)}}>Update Pin</Button>}
                      </TableCell>
                    
                </TableRow>
              );
            })}
          </TableBody> }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {show && (
        <WardenModal
          show={show}
          setShow={setShow}
          wardenData={warden}
          setWardenData={setWarden}
        />
      )}
      {updatePinShow && (
        <WardenPinModel
          show={updatePinShow}
          setShow={setUpdatePinShow}
          wardenData={warden}
          setWardenData={setWarden}
        />
      )}
    </div>
  );
};

// export default Warden_DataTable;
