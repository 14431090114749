import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  FieldPath,
} from "firebase/firestore";
import { db } from "../firebase";
import { getAttendance } from "../store/attendanceSlice";

export const getAttendanceDetails = async (dispatch, payload) => {
  try {
    const q = query(collection(db, "attendence"));
    const res = await onSnapshot(q, (querySnapshot) => {
      let sortedArr = [...querySnapshot.docs];
      sortedArr.sort((a, b) => new Date(b.id) - new Date(a.id));
      dispatch(
        getAttendance(
          sortedArr.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
    });
    return res;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};

export const updateAttendance = async (dispatch, payload) => {
  const attRef = doc(db, "attendence", "2022-10-08");
  try {
    const { data, id } = payload;

    await updateDoc(attRef, {});
    // const attRef = db
    //   .collection("attendence")
    //   .doc();
    // const res = await attRef.update({ isAbsent: true });
    // console.log(res);
    return;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
