import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateAttendance } from "../apis/attendanceApi";

const columns = [
  { id: "srNo", label: "Sr no.", minWidth: 70, align: "right" },
  { id: "name", label: "Name", minWidth: 70, align: "center" },
  {
    id: "isPresent",
    label: "Attendance",
    minWidth: 70,
    align: "center",
  },

  {
    id: "date",
    label: "Date",
    minWidth: 110,
    align: "center",
  },

  {
    id: "hostel",
    label: "Hostel",
    minWidth: 70,
    align: "center",
  },
  {
    id: "warden",
    label: "Approved By Warden",
    minWidth: 70,
    align: "center",
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}
export default function DataTable(props) {
  const { searchedName } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [isPresent, setIsPresent] = React.useState([]);
  // Distructuring the searchedName

  const { wardenDetails } = useSelector((state) => state.warden);
  const { attendanceDetails } = useSelector((state) => state.attendance);
  //initialize datatable

  const setAttendace = () => {
    setIsPresent(!isPresent);
    // updateAttendance(dispatch,{isPresent:true})
  };

  // const empty = Array.from(
  //     document.getElementsByClassName("dataTables_empty")
  // );
  // empty.forEach((element) => {
  //     element.style.display = "none";
  // });

  var Items = [];

  var srno = 1;
  attendanceDetails.forEach((item) => {
    var itemData = item.data.attendence;
    itemData.forEach((i) => {
      let itemDataEach = {
        id: i.attendeceId,
        srNo: srno,
        name: i.studentName,
        hostel: i.hostelName,
        date: item.id,
        isPresent: `${i.isPresent ? "Present" : "Absent"}`,
        warden: `${wardenDetails
          .filter((war) => war.id === i.wardenId)
          .map((war) => war.data.name)}`,
      };
      Items.push(itemDataEach);
      srno += 1;
    });
  });
  // const handleAttendance = async (item) => {
  //   const payload = {
  //     id: item.id,
  //     data: { isPresent: item.isPresent == "Present" ? false : true },
  //   };
  //   await updateAttendance(dispatch, payload);
  // };
  // handleAttendance();

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          ml: 0,
          backgroundColor: "white",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ textAlign: "center" }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      backgroundColor: "rgb(164 208 241)",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {searchedName ? (
              <TableBody>
                {Items.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                  .filter((item) => {
                    return item.name
                      .toLowerCase()
                      .includes(searchedName.toLowerCase());
                    // const lowerCase = searchedName.toLowerCase();
                    // if no input the return the original
                    // if (lowerCase === "") {
                    return true;
                    // }
                    // return the item which contains the user input
                    // return item.name.toLowerCase().includes(lowerCase);
                  })
                  .map((item) => {
                    return (
                      <TableRow hover tabIndex={-1} key={item.code}>
                        <TableCell align="center">{item.srNo}</TableCell>
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">
                          <label
                            // onClick={() => handleAttendance(item)}
                            // variant="contained"
                            // align="right"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: `${
                                item.isPresent == "Present"
                                  ? "#1eba1b"
                                  : "rgb(221 13 13)"
                              }`,
                              color: "white",
                              fontSize: "15px",
                              width: "6rem",
                              height: "1.7rem",
                              borderRadius: "7px",
                              border: "0px",
                            }}
                          >
                            {item.isPresent}
                          </label>
                        </TableCell>
                        <TableCell align="center">{item.date}</TableCell>
                        <TableCell align="center">{item.hostel}</TableCell>
                        <TableCell align="center">{item.warden}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (
              <>
                <TableBody>
                  {Items.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((item) => {
                    return (
                      <TableRow hover tabIndex={-1} key={item.code}>
                        <TableCell align="center">{item.srNo}</TableCell>
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">
                          <label
                            // onClick={() => handleAttendance(item)}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: `${
                                item.isPresent == "Present"
                                  ? "#1eba1b"
                                  : "rgb(221 13 13)"
                              }`,
                              color: "white",
                              fontSize: "15px",
                              width: "6rem",
                              height: "1.7rem",
                              borderRadius: "7px",
                              border: "0px",
                            }}
                          >
                            {item.isPresent}
                          </label>
                        </TableCell>
                        <TableCell align="center">{item.date}</TableCell>
                        <TableCell align="center">{item.hostel}</TableCell>
                        <TableCell align="center">{item.warden}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
