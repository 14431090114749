const data_table = [
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    From: "20/01/2022",
    Time: "10:00 AM",
    To: "24/01/2022",
    TTime: "10:00 PM",
    Approved: "Santosh Kumar",
    Reason: "Lorem",
  },
];

module.exports = data_table;
