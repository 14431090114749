import React from "react";
import "./Navbar.css";
import logo from "../img.png";
import img from "../Dashboard/warden-demo.png";
import Moment from "moment";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  // Getting the current date

  const currentDate = Moment().format("MMMM Do YYYY");
  // Getting the day

  const currentDay = Moment().format("dddd");
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/", { replace: true });
  };
  return (
    <div className="navbar sticky-top">
      <img src={logo} alt="" width="72px" height="69px" className="logo" />
      <div>
        <time>
          {currentDay}, {currentDate}
        </time>
      </div>
      <button className="profile-img">
        <Button
          onClick={handleLogOut}
          sx={{
            mr: "1rem",
            position: "relative",
            // right: "20px",
            backgroundColor: "",
            width: "8rem",
            textAlign: "center",
          }}
          variant="contained"
        >
          Log Out
        </Button>
        <img src={img} alt="" width="72px" height="69px" />
      </button>
    </div>
  );
};

export default Navbar;
