import React from "react"

export default function ContactUsPage() {
  return (
    <>
      {/* 
<!doctype html>
<html lang="en" dir="ltr">
  <head>
    <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
      <title>VDFA Contact Us</title>
     
      <!-- Google Font Api KEY-->
      <meta name="google_font_api" content="AIzaSyBG58yNdAjc20_8jAvLNSVi9E4Xhwjau_k">
      
      <!-- Config Options -->
      <meta name="setting_options" content='{&quot;saveLocal&quot;:&quot;sessionStorage&quot;,&quot;storeKey&quot;:&quot;huisetting&quot;,&quot;setting&quot;:{&quot;app_name&quot;:{&quot;value&quot;:&quot;Hope UI&quot;},&quot;theme_scheme_direction&quot;:{&quot;value&quot;:&quot;ltr&quot;},&quot;theme_scheme&quot;:{&quot;value&quot;:&quot;light&quot;},&quot;theme_style_appearance&quot;:{&quot;value&quot;:[&quot;theme-default&quot;]},&quot;theme_color&quot;:{&quot;colors&quot;:{&quot;--{{prefix}}primary&quot;:&quot;#3a57e8&quot;,&quot;--{{prefix}}info&quot;:&quot;#08B1BA&quot;},&quot;value&quot;:&quot;theme-color-default&quot;},&quot;theme_transition&quot;:{&quot;value&quot;:&quot;theme-with-animation&quot;},&quot;theme_font_size&quot;:{&quot;value&quot;:&quot;theme-fs-md&quot;},&quot;page_layout&quot;:{&quot;value&quot;:&quot;container-fluid&quot;},&quot;header_navbar&quot;:{&quot;value&quot;:&quot;default&quot;},&quot;header_banner&quot;:{&quot;value&quot;:&quot;default&quot;},&quot;sidebar_color&quot;:{&quot;value&quot;:&quot;sidebar-white&quot;},&quot;sidebar_type&quot;:{&quot;value&quot;:[]},&quot;sidebar_menu_style&quot;:{&quot;value&quot;:&quot;left-bordered&quot;},&quot;footer&quot;:{&quot;value&quot;:&quot;default&quot;},&quot;body_font_family&quot;:{&quot;value&quot;:null},&quot;heading_font_family&quot;:{&quot;value&quot;:null}}}'>
      <!-- Favicon -->
      <link rel="shortcut icon" href="assets/images/vdfa_logo_red.png" />
      
      <!-- Library / Plugin Css Build -->
      <link rel="stylesheet" href="assets/css/core/libs.min.css" />
      

      <!-- Sweetlaert2 css -->
      <link rel="stylesheet" href="assets/vendor/sweetalert2/dist/sweetalert2.min.css"/>
    
      <!-- Hope Ui Design System Css -->
      <link rel="stylesheet" href="assets/css/hope-ui.min.css?v=1.1.1" />
      <link rel="stylesheet" href="assets/css/pro.min.css?v=1.1.1" />
      
      <!-- Custom Css -->
      <link rel="stylesheet" href="assets/css/custom.min.css?v=1.1.1" />
      
      <!-- Dark Css -->
      <link rel="stylesheet" href="assets/css/dark.min.css?v=1.1.1"/>
      
      <!-- Customizer Css -->
      <link rel="stylesheet" href="assets/css/customizer.min.css?v=1.1.1" />
      
      <!-- RTL Css -->
      <link rel="stylesheet" href="assets/css/rtl.min.css?v=1.1.1"/>
      
      <!-- Google Font -->
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap" rel="stylesheet">
      <style>
        .bg-img
        {
            background: linear-gradient(180deg, #BF2132 0%, rgba(255, 255, 255, 0) 120.31%);
        }
      </style>
      <!--  Firebase services -->

      <script src="https://www.gstatic.com/firebasejs/7.23.0/firebase-app.js"></script>
      <script src="https://www.gstatic.com/firebasejs/7.23.0/firebase-analytics.js"></script>
      <script src="https://www.gstatic.com/firebasejs/7.23.0/firebase-auth.js"></script>
      <script src="https://www.gstatic.com/firebasejs/7.23.0/firebase-firestore.js"></script>
      <!--  Firebase services -->

      <script type="text/javascript" src="./assets/js/firebase-config.js"></script>
      <script src="https://smtpjs.com/v3/smtp.js"></script>

  </head>
  <body class=" ">
    <!-- loader Start --> */}
      <div id='loading'>
        <div class='loader simple-loader'>
          <div class='loader-body'>
            {/* <img
              src='assets/images/loader.webp'
              alt='loader'
              class='light-loader img-fluid w-25'
              width='200'
              height='200'
            /> */}
          </div>
        </div>
      </div>
      <div class='wrapper bg-img'>
        <div class='iq-auth-page'>
          <nav class='navbar iq-auth-logo'>
            <div class='container-fluid '>
              <a href='#' class='iq-link d-flex align-items-center'></a>
            </div>
          </nav>

          <div
            class='row m-0 p-0 d-flex align-items-center iq-auth-container w-100'
            style={{ justifyContent: "center" }}>
            <div class='col-12 col-md-5 col-xl-5 col-sm-6 '>
              <div class='text-center '>
                {/* <img
                  class='d-md-none'
                  src='assets/images/vd_pic_bw.png'
                  alt='logo'
                  loading='lazy'
                  width='80'
                  height='80'
                /> */}
                {/* <img
                  src='assets/images/vdfa_logo_white.png'
                  alt='logo'
                  loading='lazy'
                  width='80'
                  height='80'
                /> */}
              </div>

              <div class='card w-80'>
                <div class='card-body '>
                  <h3 class='text-center'>Contact Us</h3>
                  <form
                    id='contactusform'
                    method='POST'
                    class='row g-3 needs-validation'
                    novalidate
                    action='contact-form.php'>
                    <div class='form-group'>
                      <label class='form-label' for='name'>
                        Name
                      </label>
                      <input
                        type='text'
                        class='form-control mb-0'
                        id='name'
                        name='name'
                        placeholder='Enter Name'
                        required
                      />
                      <div class='invalid-feedback'>Please provide name.</div>
                    </div>
                    <div class='form-group'>
                      <label class='form-label' for='email-id'>
                        Email address
                      </label>
                      <input
                        type='email'
                        class='form-control mb-0'
                        id='email'
                        name='email'
                        placeholder='Enter Email'
                        required
                      />{" "}
                      <div class='invalid-feedback'>Please provide email.</div>
                    </div>
                    <div class='form-group'>
                      <label class='form-label' for='message'>
                        Message
                      </label>
                      <textarea
                        class='form-control mb-0'
                        id='message'
                        name='message'
                        rows='3'
                        placeholder='Your Message'
                        required></textarea>
                      <div class='invalid-feedback'>Please provide message.</div>
                    </div>

                    <div class='text-center pb-3'>
                      <input type='submit' class='btn btn-danger' />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <script src='assets/js/core/libs.min.js'></script>

      <script src='assets/js/plugins/slider-tabs.js'></script>

      <script src='assets/vendor/sweetalert2/dist/sweetalert2.min.js' async></script>
      <script src='assets/js/plugins/sweet-alert.js' defer></script>

      <script src='assets/vendor/lodash/lodash.min.js'></script>

      <script src='assets/js/iqonic-script/utility.min.js'></script>

      <script src='assets/js/iqonic-script/setting.min.js'></script>
      <script src='assets/js/setting-init.js'></script>
      <script src='assets/js/core/external.min.js'></script>
      <script src='assets/js/charts/widgetcharts.js?v=1.1.1' defer></script>
      <script src='assets/js/charts/dashboard.js?v=1.1.1' defer></script>
      <script src='assets/js/hope-ui.js?v=1.1.1' defer></script>
      <script src='assets/js/hope-uipro.js?v=1.1.1' defer></script> */}

      {/* <!--
    <script>
    
        function validateform(formId)
        {
        $(".invalid-feedback").remove();
        var errcnt=0;
        $('#'+formId+' input, #'+formId+' select, #'+formId+' textarea').each(
            function(index){  
                var input = $(this);
                $(this).removeClass("is-valid");
                $(this).removeClass("is-invalid");
                var input_name=input.parent().find("label").text();
                // console.log(input_name);
                if(input.val()==null || input.val()=='')
                {
                $(this).addClass("is-invalid");
                $(this).parent().append("<div class='invalid-feedback'>Please enter "+input_name+"</div>");
                errcnt++;
                
                }
                else
                {
                if (!input.hasClass('is-valid'))
                {
                    $(this).addClass("is-valid");
                }  
                }
                
            });
            console.log(errcnt);
        if(errcnt==0)
            return true;
        else
            return false;
        }
        
        function submitResponse(event)
        {
            event.preventDefault();
            
            var isvalidform=validateform("contactusform");
            console.log("isvalidform : "+isvalidform);
            if(isvalidform)
            {
                var name=$("#name").val();
                var email=$("#email").val();
                var message=$("#message").val();
                console.log("name : "+name);
                console.log("email : "+email);
                console.log("message : "+message);
                document.getElementById('contactusform').action = "contact-form.php";
               // document.getElementById('contactusform').submit();


            }
        }
        // function sendEmail() {
        //     console.log("send email");
        //     Email.send({
        //         Host: "mail.outshade.net",
        //         Username: "wp@outshade.net",
        //         Password: "OSDM@2906",
        //         To: 'chaitanya.vishwanathapally@gmail.com',
        //         From: "wp@outshade.net",
        //         Subject: "Sending Email using javascript",
        //         Body: "Test message",
        //     })
        //     .then(function (message) {
        //         console.log(message);
        //         console.log("mail sent successfully")
        //     });
        // }
    </script> --> */}

      {/* </body> */}

      {/* </html> */}
    </>
  )
}
