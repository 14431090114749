import { createSlice } from "@reduxjs/toolkit";

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState: {
    attendanceDetails: [],
  },
  reducers: {
    getAttendance: (state, { payload }) => {
      state.attendanceDetails = payload;
    },
    updateAttendance: (state, { payload }) => {
      state.attendanceDetails = { ...state, payload };
    },
  },
});

export const { getAttendance, updateAttendance } = attendanceSlice.actions;

export default attendanceSlice.reducer;
