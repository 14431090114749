import "./App.css";
import React, { Component } from "react";
import Login from "./Login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Verify from "./Login/Verify";
import Dashboard from "./Dashboard/Dashboard";
import Warden from "./Warden/Warden";
import Student from "./Student/Student";
import Security from "./Security/Security";
import StudentProfile from "./StudentProfile/StudentProfile";
import WardenProfile from "./WardenProfile/WardenProfile";
import SecurityProfile from "./SecurityProfile/SecurityProfile";
import { useState } from "react";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { getAttendanceDetails } from "./apis/attendanceApi";
import { useDispatch, useSelector } from "react-redux";
import { getOutPassDetails } from "./apis/outPassApi";
import { getWardenDetails } from "./apis/wardenApi";
import { getStudentsDetails } from "./apis/studentApi";
import { getSecurityDetails } from "./apis/securityApi";
import { login } from "./apis/adminLogin";
import { useReducer } from "react";
import { PrivateRoute } from "./privateRoute";
import PrivacyPolicyPage from "./privacy-policy";
import ContactUsPage from "./contact-us";
import { getAttendance } from "./store/attendanceSlice";
import { setRecaptcha } from "./store/loginSlice";
import { getHostelDetails } from "./apis/hostelApis";

function App() {
  const dispatch = useDispatch();
  let obj;
  const [Obj, setObj] = useState("");
  const getObj = (object) => {
    obj = object;
    setObj(obj);
  };
  getAttendanceDetails(dispatch);
  getOutPassDetails(dispatch);
  getWardenDetails(dispatch);
  getHostelDetails(dispatch);
  getStudentsDetails(dispatch);
  getSecurityDetails(dispatch);
  login(dispatch);

  return (
    <div className="main-app">
      <Router>
        <Routes>
          <Route path="/" element={<Login getObj={getObj} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/verification" element={<Verify obj={Obj} />} />
          <Route
            path="/dashboard"
            element={<PrivateRoute component={Dashboard} />}
          />
          {/* <Route path="/dashboard" element={<PrivateRoute component={Dashboard} /> */}
          <Route path="/warden" element={<PrivateRoute component={Warden} />} />
          <Route
            path="/student"
            element={<PrivateRoute component={Student} />}
          />
          <Route
            path="/security"
            element={<PrivateRoute component={Security} />}
          />
          <Route
            path="/home"
            element={<PrivateRoute component={Dashboard} />}
          />
          <Route
            path="/studentprofile"
            element={<PrivateRoute component={StudentProfile} />}
          />
          <Route
            path="/wardenprofile"
            element={<PrivateRoute component={WardenProfile} />}
          />
          <Route
            path="/securityprofile"
            element={
              <PrivateRoute component={SecurityProfile} getObj={getObj} />
            }
          />
          <Route
            path="/privacypolicy"
            element={<PrivateRoute component={PrivacyPolicy} />}
            // element={<PrivacyPolicy/>}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
