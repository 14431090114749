import React, { useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import img from "../Dashboard/warden-demo.png";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  formControlLabelClasses,
  Input,
  Typography
} from "@mui/material";
import { setPage, setRecaptcha } from "../store/loginSlice";
import { setCurrentStudentId, setUpdateStudent } from "../store/studentsSlice";
import { setUpRecaptcha } from "../helpers/setUpRecaptcha";
import VerifyModal from "../Components/optValidationModal";
import { deleteStudent } from "../apis/studentApi";

const Student_Modal = (props) => {
  const { studentData, show, setShow, setStudentData } = props;
  const data = studentData.data;
  const id = studentData.id;
  const [name, setName] = useState(data.name);
  const [gender, setGender] = useState(data.gender);
  const [address, setAddress] = useState(data.address);
  const [mobile, setMobile] = useState(data.mobileNumber);
  const [dob, setDob] = useState(data.dob);
  const [aadhar, setAadhar] = useState(data.aadharNumber);
  const [hostelName, setHostelName] = useState(data.hostelName);
  const [warden, setWarden] = useState(data.wardenName);
  const [delConfirm, setDelConfirm] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [aadharErr, setAadharErr] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [isApproved, setIsApproved] = useState(
    data.isApproved == true ? "Approved" : "Not Approved"
  );
  const { hostelDetails } = useSelector((state) => state.hostel);
  let hostelNameList = [];
  hostelDetails.map((hostel) => {
    hostel.data.hostelList.map((list) => {
      hostelNameList.push(list);
    });
  });
  const handleMobNum = (e) => {
    setMobile(e.target.value);
    if (e.target.value.length === 10) {
      setMobileErr(false);
    } else {
      setMobileErr(true);
    }
  };
  const handleAadNum = (e) => {
    setAadhar(e.target.value);
    if (e.target.value.length === 12) {
      setAadharErr(false);
    } else {
      setAadharErr(true);
    }
  };
  const { wardenDetails } = useSelector((state) => state.warden);
  let wardenNameList = [];
  wardenDetails.map((warden) => {
    wardenNameList.push(warden.data.name);
  });

  const deleteHandler = async () => {
    try {
      await deleteStudent(id);
    } catch (err) {
      console.log(err);
    }
    setShow(false);
    setStudentData([]);
  };
  const dispatch = useDispatch();

  const updateApprovedStatus = async () => {
    const taskDocRef = doc(db, "students", id);
    try {
      await updateDoc(taskDocRef, {
        isApproved: isApproved == "Approved" ? false : true
      });
      setIsApproved(isApproved == "Approved" ? "Not Approved" : "Approved");
    } catch (err) {
      console.log(err);
    }
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    if (e.target.checkValidity() && !mobileErr && !aadharErr) {
      if (mobile !== data.mobileNumber) {
        changeMobileNumber();
        return;
      }
      const taskDocRef = doc(db, "students", id);
      try {
        await updateDoc(taskDocRef, {
          name,
          address,
          dob,
          mobileNumber: mobile,
          gender,
          aadharNumber: aadhar,
          hostelName
        });
      } catch (err) {
        console.log(err);
      }
      setShow(false);
      setStudentData([]);
    } else {
      alert("Missing fields !!!");
    }
  };

  const changeMobileNumber = async () => {
    try {
      let number = "+91" + mobile;
      const response = await setUpRecaptcha(number);
      if (response !== undefined) {
        dispatch(setRecaptcha(response));

        dispatch(
          setUpdateStudent({
            name,
            name,
            address,
            dob,
            mobileNumber: mobile,
            gender,
            aadharNumber: aadhar,
            hostelName
          })
        );
        dispatch(setPage("securityModal"));
        dispatch(setCurrentStudentId(id));

        setOtpShow(true);
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
      setOtpShow(false);
      setShow(false);
      setOtpShow(false);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header>
          <img
            src={img}
            alt=""
            height="30px"
            width="30px"
            style={{ borderRadius: "2rem", margin: "0 5px" }}
          />
          <span style={{ fontFamily: "Inter" }}>Student Profile</span>
        </Modal.Header>
        <Modal.Body>
          <form className="row warden-add-form">
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Name</label>
              <br />
              <input
                required
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 small-inputs">
              <label style={{ fontFamily: "Inter" }}>Date of Birth</label>
              <br />
              <input
                required
                type="text"
                value={dob}
                onChange={(e) => {
                  setDob(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 small-inputs">
              <label style={{ fontFamily: "Inter" }}>Gender</label>
              <br />
              <input
                required
                type="text"
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Mobile Number</label>
              <br />
              <input
                style={{ border: `1px solid ${mobileErr ? "red " : "white"}` }}
                value={mobile}
                onChange={(e) => handleMobNum(e)}
              />
            </div>
            <div id="recaptcha-container"></div>
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Aadhar Number</label>
              <br />
              <input
                style={{ border: `1px solid ${aadharErr ? "red " : "white"}` }}
                value={aadhar}
                onChange={(e) => handleAadNum(e)}
              />
            </div>
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Hostel Name</label>
              <br />
              {/* <input
                required
                type="text"
                value={hostelName}
                onChange={(e) => {
                  setHostelName(e.target.value);
                }}
              /> */}
              <Autocomplete
                sx={{ backgroundColor: "inherit" }}
                value={hostelName}
                options={hostelNameList}
                onChange={(event, newValue) => setHostelName(newValue)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input type="text" {...params.inputProps} />
                  </div>
                )}
              />
            </div>
            {/* <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Block Name</label>
              <br />
              <input
                required
                type="text"
                value={hostelName}
                onChange={(e) => {
                  setHostelName(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Block Number</label>
              <br />
              <input required type="text" value="101" />
            </div> */}
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Warden Name</label>
              <br />
              <Autocomplete
                sx={{ backgroundColor: "inherit" }}
                value={warden}
                options={wardenNameList}
                onChange={(event, newValue) => setWarden(newValue)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input type="text" {...params.inputProps} />
                  </div>
                )}
              />
            </div>
            <div className="col-lg-12">
              <label style={{ fontFamily: "Inter" }}>Address</label>
              <br />
              <textarea
                required
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer-buttons">
            <Button
              style={{
                fontFamily: "Inter",
                fontSize: "12px",
                backgroundColor: `${
                  isApproved == "Approved" ? "#1eba1b" : "rgb(221 13 13)"
                }`
              }}
              onClick={updateApprovedStatus}
            >
              {isApproved}
            </Button>
            <Button style={{ fontFamily: "Inter" }} onClick={updateHandler}>
              Update
            </Button>
            <Button
              style={{ fontFamily: "Inter" }}
              className="btn btn-danger"
              onClick={() => setDelConfirm(true)}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={delConfirm}
        onHide={() => {
          setDelConfirm(false);
        }}
      >
        <Modal.Body>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center"
            }}
          >
            <Typography
              variant="h6"
              sx={{ margin: "auto", fontFamily: "Inter", mb: 2 }}
            >
              Confirm Delete
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}
            >
              <Button
                style={{ fontFamily: "Inter", marginRight: "2rem" }}
                className="btn btn-danger"
                onClick={deleteHandler}
              >
                Delete
              </Button>
              <Button
                style={{ fontFamily: "Inter" }}
                onClick={() => setDelConfirm(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
      <Modal show={otpShow} onHide={() => setOtpShow(false)}>
        <ModalBody>
          <VerifyModal
            setShow={(val) => setShow(val)}
            setOtpShow={(val) => setOtpShow(val)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Student_Modal;
