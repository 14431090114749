import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { getAttendance } from "../store/attendanceSlice";
import { getOutPass } from "../store/outPassSlice";

export const getOutPassDetails = async (dispatch, payload) => {
  try {
    const q = query(collection(db, "outpasses"), orderBy("from", "desc"));
    const res = await onSnapshot(q, (querySnapshot) => {
      dispatch(
        getOutPass(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
    });
    return res;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};

export const updateOutPass = async (dispatch, payload) => {
  try {
    const { data } = payload;
    const res = await db
      .collection("outpasses")
      .doc(payload.id)
      .update({ data });
    getOutPassDetails(dispatch);
    return res;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
