import React, { useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { Box } from "@mui/material";
import img from "../Dashboard/warden-demo.png";
import * as functions from "firebase/functions";


const WardenPinModel = (props) => {

  const { show, setShow, wardenData, setWardenData } = props;

  const [pin, setPin] = useState("");

  const updateHandler = async (e) => {
    e.preventDefault();
    if(pin.length === 6) {
      updatePassword();
    }
  };

  const updatePassword = async () => {
    try {
      const updatePinFunction = functions.httpsCallable(functions.getFunctions(), 'updateWardenPin');
      const result = await updatePinFunction({
        uid: wardenData.id,
        newPassword: pin
      });
      
      alert("Pin updated successfully");
      setShow(false);
    } catch (error) {
      console.error(error);
      alert("An error occurred while updating the pin");
    }
  };

  return <>
    <Modal show={show} onHide={()=>setShow(false)}>
      <Modal.Header>
      <img
            src={img}
            alt=""
            height="30px"
            width="30px"
            style={{ borderRadius: "2rem", margin: "0 5px" }}
          />{" "}
          <span style={{ fontFamily: "Inter" }}>Update Warden Pin</span>
      </Modal.Header>
      <Modal.Body>
        <form className="row warden-add-form" onSubmit={updateHandler}>
          <div className="col-lg-12">
            <label style={{ fontFamily: "Inter" }}>New Pin</label>
            <br />
            <input
              required
              type="number"
              value={pin}
              onChange={(e) => {
                if(e.target.value.length <= 6) {
                setPin(e.target.value);
                }
              }}
            />
          </div>
        </form>
        <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}
            >
              <Button
                style={{ fontFamily: "Inter" }}
                onClick={() => updatePassword()}
              >
                Update
              </Button>
            </Box>
      </Modal.Body>
    </Modal>
  </>;
};

export default WardenPinModel;