import React, { useState } from "react";
import Sidebar from "../Components/Sidebar";
import "./SecurityProfile.css";
import Navbar from "../Components/Navbar";
import { db } from "../firebase";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Col, Container, Modal, ModalBody, Row } from "react-bootstrap";
import { alertClasses, Input } from "@mui/material";
import { setUpRecaptcha } from "../helpers/setUpRecaptcha";
import { useDispatch } from "react-redux";
import { addNewSecurity } from "../store/securitySlice";
import Verify from "../Login/Verify";
import { setPage, setRecaptcha, setUuId } from "../store/loginSlice";
import VerifyModal from "../Components/optValidationModal";
import { addSecurity } from "../apis/securityApi";

const SecurityProfile = (props) => {
  const { getObj } = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [mobile, setMobile] = useState("");
  const [gateNo, setGateNo] = useState("");
  const [gender, setGender] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [mobileErr,setMobileErr]=useState(false);
  const [aadharErr,setAadharErr]=useState(false);
  const [otpShow,setOtpShow]=useState(false);

  const handleMobNum=(e)=>{
    setMobile(e.target.value)
    if(e.target.value.length ==10){
      setMobileErr(false);
    }
    else{
      setMobileErr(true);

    }
  }
  const handleAadNum=(e)=>{
    setAadhar(e.target.value);
    if(e.target.value.length ==12){
      setAadharErr(false)
        }else{
          setAadharErr(true)
        }

  }

  const newSecurity = {
    name: name,
    mobileNumber: mobile,
    address: address,
    dob: dob,
    aadharNumber: aadhar,
    gender: gender,
    gateNo: gateNo,
    workingHours: workingHours,
  };
  const dispatch=useDispatch()
  const handleAdd = async (e) => {
    e.preventDefault()
   if(e.target.checkValidity() && !mobileErr && !aadharErr ){ try {
     let number = "+91" + mobile;
     const response = await setUpRecaptcha(number);
     if (response !== undefined) {
    
      dispatch(setRecaptcha(response))
      dispatch(addNewSecurity(newSecurity))
      dispatch(setPage("security"))
   
    setOtpShow(true)
  }
    } catch (err) {
      console.log(err); 
    }
  }else{
    alert("Missing Field !!!")
  }
  ;}

  // {
  //   state != null ? handleAdd() : console.log("null");
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // navigate("/security", {
      //   state: {
      //     propsMobile: mobile,
      //     page: "security",
      //     name,
      //     address,
      //     dob,
      //     gateNo,
      //     gender,
      //     aadhar,
      //   },
      // });
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <div className="navmain">
        <Navbar />
      </div>

      <div className="wardenmain">
      <Container>
        <Row>
          <Col sm={12} lg={2}>
            <Sidebar />
          </Col>
          <Col sm={12} lg={10} className="mt-3">
            <Container>
              <Row>
                <Col sm={12}>
                  <div className="heading">
                    <Link to="/home">Dashboard</Link> /{" "}
                    <Link to="/security">Security</Link> /
                    <span className="warden-span">&nbsp; Security Profile</span>
                  </div>
                </Col>
                <Col sm={12}>
                  <h2 style={{ fontFamily: "Inter" }}>Security Profile</h2>
                  <form className="row security-form" onSubmit={handleAdd}>
                    <div className="col-lg-4 form-items">
                      <div>
                        <label style={{ fontFamily: "Inter" }}>Name</label>
                        <br />
                        <input
                          required
                          type="text"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter" }}>Address</label>
                        <br />
                        <textarea
                          required
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 form-items">
                      <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Date of Birth
                        </label>
                        <br />
                        <input
                          required
                          type="text"
                          value={dob}
                          onChange={(e) => {
                            setDob(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Mobile Number
                        </label>
                        <br />
                        <input
                       type="number"
                       style={{border:`1px solid ${mobileErr ? "red ": "white"}`}}
                        value={mobile}
                        onChange={(e)=>handleMobNum(e)}
                        />
                       
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Gate Number
                        </label>
                        <br />
                        <input
                          required
                          type="text"
                          value={gateNo}
                          onChange={(e) => {
                            setGateNo(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 form-items">
                      <div>
                        <label style={{ fontFamily: "Inter" }}>Gender</label>
                        <br />
                        <input
                          required
                          type="text"
                          value={gender}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Working Hours
                        </label>
                        <br />
                        <input
                          required
                          type="text"
                          value={workingHours}
                          onChange={(e) => {
                           setWorkingHours(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label style={{ fontFamily: "Inter" }}>
                          Aadhaar Number
                        </label>
                        <br />
                        <input
                       type="number"
                       style={{border:`1px solid ${aadharErr ? "red ": "white"}`}}
                        value={aadhar}
                        onChange={(e)=>handleAadNum(e)}
                        />
                      </div>
                    </div>
                    <div style={{marginTop:"2rem"}}id="recaptcha-container"></div>
                    <div className="buttons">
                      <button
                        className="btn btn-primary"
                        
                      >
                        Add New
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          navigate("/security");
                        }}
                      >
                        Go Back
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
             
            </Container>
          </Col>
        </Row>
      </Container>
      <Modal show={otpShow}  onHide={()=>(setOtpShow(false))}>
          <ModalBody >
        <VerifyModal setOtpShow={(val)=>setOtpShow(val)} setShow={(val)=>setOtpShow(val)}/>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default SecurityProfile;
