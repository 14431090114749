// import jwt from "jsonwebtoken";

const generateToken = async (id) => {
  // return await jwt.sign(
  //   { id },
  //   "RGMCET",
  //   // TODO: needs to be changed to different value after expiry period
  //   { expiresIn: "10d" }
  // );
  return Math.random() * 100000;
};

export default generateToken;
