import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import Home from "../Dashboard/Home_Icon.png";
import HomeActive from "../Dashboard/Home_Icon_Active.png";
import Security from "../Dashboard/Security_Icon.png";
import Student from "../Dashboard/Student_Icon.png";
import Warden from "../Dashboard/Warden_Icon.png";
import StudentActive from "../Dashboard/Student_Icon_Active.png";
import WardenActive from "../Dashboard/Warden_Icon_Active.png";
import SecurityActive from "../Dashboard/Security_Icon_Active.png";
import PrivacyPolicy from "../Dashboard/PrivacyIcons.png";
import $ from "jquery";

// ChartBar Icon
import { ChartBar } from "phosphor-react";

const Sidebar = () => {
  var location = useLocation();
  var id = location.pathname.slice(1);

  const active = () => {
    id = location.pathname.slice(1);
    $(document).ready(function() {
      $(`#${id}`).addClass("active");
    });
  };
  const HomeIcon = id === "home" ? HomeActive : Home;
  const WardenIcon = id === "warden" ? WardenActive : Warden;
  const StudentIcon = id === "student" ? StudentActive : Student;
  const SecurityIcon = id === "security" ? SecurityActive : Security;
  const AnalyticsIcon = id === "analytics" ? "" : "";

  useEffect(() => {
    if (id.length > 8) {
      if (id === "studentprofile") {
        id = "student";
      } else if (id === "wardenprofile") {
        id = "warden";
      } else if (id === "securityprofile") {
        id = "security";
      } else if (id === "analytics") {
        id = "analytics";
      } else {
        id = "";
      }
    }
    active();
  }, []);

  return (
    <div class="sidebar">
      <Link to="/home" className="sidebar-links" id="home">
        <a href="#home">
          <img src={HomeIcon} alt="" height="21px" width="21px" />
        </a>
        Dashboard
      </Link>
      <hr />
      <Link to="/warden" className="sidebar-links" id="warden">
        <a href="#warden">
          <img src={WardenIcon} alt="" height="21px" width="21px" />
        </a>
        Warden
      </Link>
      <hr />
      <Link to="/student" className="sidebar-links" id="student">
        <a href="#student">
          <img src={StudentIcon} alt="" height="21px" width="21px" />
        </a>
        Student
      </Link>
      <hr />

      <Link to="/security" className="sidebar-links" id="security">
        <a href="#security">
          <img src={SecurityIcon} alt="" height="21px" width="21px" />
        </a>
        Security
      </Link>
      <hr />

      {/* <Link to="/privacypolicy" className="sidebar-links" id="privacy">
        <a href="#privacy">
          <img src={PrivacyPolicy} alt="" height="21px" width="21px" />
        </a>
        Privacy
      </Link> */}
    </div>
  );
};

export default Sidebar;
