import React, { useState } from "react";
import Sidebar from "../Components/Sidebar";
import Navbar from "../Components/Navbar";
import "./Dashboard.css";
import AttendanceChart from "./AttendanceChart";
import OutpassDataTable from "./Outpass_DataTable";
import AttendanceDataTable from "./AttendanceDataTable";
import OutpassChart from "./OutpassChart";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import img from "./warden-demo.png";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";

// moment for date
import Moment from "moment";

// Icon
import { MagnifyingGlass } from "phosphor-react";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import DataTable from "../Components/DataTable";

const Dashboard = () => {
  const [showOutpass, setShowOutpass] = useState(false);
  const handleModalOutpass = () => {
    setShowOutpass(true);
  };

  // Search input state
  const [attendanceInput, setAttendanceInput] = useState("");
  const [outpassInput, setOutpassInput] = useState("");

  const outPassInputHandler = (e) => {
    setOutpassInput(e.target.value);
  };
  const attendanceInputHandler = (e) => {
    setAttendanceInput(e.target.value);
  };

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  // Getting the current date
  const currentDate = Moment().format("MMMM Do YYYY");
  // Getting the day
  const currentDay = Moment().format("dddd");

  const { attendanceDetails } = useSelector((state) => state.attendance);
  const { loginDetails } = useSelector((state) => state.login);

  return (
    <>
      <div className="navmain">
        <Navbar />
      </div>
      <div className="mainDash">
        <Container>
          <Row>
            <Col sm={12} lg={2}>
              <Sidebar />
            </Col>
            {attendanceDetails.length == 0 ? (
              <Paper
                sx={{
                  backgroundColor: "inherit",
                  mt: 20,
                  height: "100%",
                  width: "110%",
                  pr: 2,
                  display: "flex",
                  alignContent: "center",
                  alignItem: "center",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Paper>
            ) : (
              <Col sm={12} lg={10} className="mt-3">
                <div className="dashmain">
                  <Container>
                    <Row>
                      <Col
                        sm={{ span: 8, offset: 2 }}
                        lg={{ span: 5, offset: 1 }}
                        className="mb-3"
                      >
                        <AttendanceChart />
                      </Col>
                      <Col
                        sm={{ span: 8, offset: 2 }}
                        lg={{ span: 5, offset: 1 }}
                        className="mb-3"
                      >
                        <OutpassChart />
                      </Col>
                    </Row>
                    <Row>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#1666ba",
                          pb: 2,
                          fontFamily: "monospace",
                          fontWeight: "700",
                          textDecoration: "underline",
                        }}
                      >
                        OUTPASS
                      </Typography>
                      <Col
                        sm={12}
                        className="d-flex gap-2 dashsearchbar"
                        // style={{ width: "130%", right: 0, marginRight: "0px" }}
                      >
                        <InputGroup>
                          <InputGroup.Text id="basic-addon2">
                            <MagnifyingGlass size={25} color="#5c94cf" />
                          </InputGroup.Text>
                          <Form.Control
                            onChange={outPassInputHandler}
                            placeholder="Search by name"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                        </InputGroup>
                        {/* <Link
                          to="/studentProfile"
                          className="button add-button"
                        >
                          + Add New
                        </Link> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="d-flex gap-2">
                        {!outpassInput ? (
                          <OutpassDataTable style={{ paddingTop: "0px" }} />
                        ) : (
                          <OutpassDataTable searchedName={outpassInput} />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#1666ba",
                          pb: 2,
                          fontFamily: "monospace",
                          fontWeight: "700",
                          textDecoration: "underline",
                        }}
                      >
                        ATTENDANCE
                      </Typography>
                      <Col sm={12} className="d-flex gap-2">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon2">
                            <MagnifyingGlass size={25} color="#5c94cf" />
                          </InputGroup.Text>
                          <Form.Control
                            onChange={attendanceInputHandler}
                            placeholder="Search by name"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                        </InputGroup>
                        {/* <Link
                          to="/studentProfile"
                          className="button add-button"
                        >
                          + Add New
                        </Link> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={14}>
                        {!attendanceInput ? (
                          <DataTable />
                        ) : (
                          <DataTable searchedName={attendanceInput} />
                        )}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            )}
          </Row>
        </Container>

        <Modal show={showOutpass}>
          <Modal.Header>
            <img
              src={img}
              alt=""
              height="30px"
              width="30px"
              style={{ borderRadius: "2rem", margin: "0 5px" }}
            />
            <span style={{ fontFamily: "Inter" }}>Warden Profile</span>
          </Modal.Header>
          <Modal.Body>
            <form className="row warden-add-form">
              <div className="col-lg-12">
                <label>Name</label>
                <br />
                <input type="text" value="Ajay Devraj" />
              </div>
              <div className="col-lg-6 small-inputs">
                <label>Date of Birth</label>
                <br />
                <input type="text" value="28/09/2022" />
              </div>
              <div className="col-lg-6 small-inputs">
                <label>Gender</label>
                <br />
                <input type="text" value="Male" />
              </div>
              <div className="col-lg-12">
                <label>Aadhar Number</label>
                <br />
                <input type="text" value="78945612310" />
              </div>
              <div className="col-lg-12">
                <label>Mobile Number</label>
                <br />
                <input type="text" value="7896541230" />
              </div>
              <div className="col-lg-12">
                <label>Gate Number</label>
                <br />
                <input type="text" value="1" />
              </div>
              <div className="col-lg-12">
                <label>Address</label>
                <br />
                <textarea value="Lorem ipsum dolor sit amet." />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer-buttons">
              <Button
                onClick={() => {
                  setShowOutpass(false);
                }}
              >
                Close
              </Button>
              <Button className="btn btn-danger">Save</Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Dashboard;
