import React, { useEffect, useState } from "react";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import data_table from "./outpass_data_tale";
import "../Components/dataTable.css";
//firebase

import { getOutPassDetails } from "../apis/outPassApi";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { GridColumnsPanel } from "@mui/x-data-grid";
import { DateCon, timeCC } from "../helpers/timeConverter";
import { Box, label, Typography } from "@mui/material";
import StudentDataTable from "../Student/Student_DataTable";
import { CloudSlash } from "phosphor-react";

const OutpassDataTable = (props) => {
  const{searchedName}=props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [outpass, setOutpass] = useState([]);
    //initialize datatable
    const dispatch=useDispatch();

    const {outPassDetails}=useSelector(state=>state.outPass)
    const {studentsDetails}=useSelector(state=>state.students)
    const {wardenDetails}=useSelector(state=>state.warden)
    useEffect(() => {
      //  getOutPassDetails(dispatch)


      //   $(document).ready(function () {
      //       $("#example").DataTable({
      //           bDestroy: true,
      //           searching: false,
      //           lengthMenu: [7, 10],
      //           aoColumnDefs: [
      //               { bSortable: false, aTargets: [0, 1, 2, 3, 4, 5, 6] },
      //               { bSearchable: false, aTargets: [0, 1, 2, 3, 4, 5, 6] },
      //           ],
      //           language: {
      //               sLengthMenu: "",
      //               // search: "_INPUT_",
      //               // searchPlaceholder: "Search by name...",
      //           },
      //           bInfo: false,
      //       });
      //   });
    }, []);

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    const items=[];
    outPassDetails.map((item,index)=>{
        items.push(
            {   id:item.id,
                name:item.data.name,
                entryTime:timeCC(item.data.entryTime),
                exitTime:timeCC(item.data.exitTime),
                status:`${item.data.status =="approved" ? "Active":"Inactive"}`,
                from:`${DateCon(item.data.from)}`,
                to:`${DateCon(item.data.to)}`,
                reason:item.data.reason,
                warden:`${wardenDetails.filter((war)=>war.id===item.data.wardenId).map((war)=>war.data.name)
                }`,
                Srno:index+1
            }
        )
    })
    console.log(outPassDetails)

    const columns = [
        { id: "Srno", label: "Sr no.", minWidth: 70, align: "right" },
        { id: "name", label: "Name", minWidth: 70, align: "center" },
        {
          id: "status",
          label: "Status",
          minWidth: 70,
          align: "center",
        },
        {
          id: "from",
          label: "From ",
          minWidth: 110,
          align: "center",
        },
        {
          id: "entryTime",
          label: "Time",
          minWidth: 70,
          align: "center",
        },
        
      
        {
          id: "to",
          label: "To",
          minWidth: 110,
          align: "center",
        },
        {
          id: "exitTime",
          label: "Time",
          minWidth: 70,
          align: "center",
        },
        {
          id: "warden",
          label: "Approved By Warden",
          minWidth: 70,
          align: "center",
        },
        {
          id: "reason",
          label: "Reason",
          minWidth: 70,
          align: "center",
        },

      
      ];
    return (
        <Box sx={{pb:5}}>
            <div >
            <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        backgroundColor: "white",
      }}
    >
      <TableContainer sx={{ maxHeight: 500  }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ textAlign: "center" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" , backgroundColor :"rgb(164 208 241)"}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {searchedName ?<TableBody>
            {items.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).filter((item)=>item.name.toLowerCase().includes(searchedName.toLowerCase())).map((item) => {
              return (
                <TableRow hover tabIndex={-1} key={item.code}>
                  
                      <TableCell align="center" >
                      {item.Srno}
                      </TableCell>
                  
                      <TableCell align="center" >
                      {item.name}
                      </TableCell>
                  
                      <TableCell  align="center">
                      <label style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems:"center",
                              backgroundColor: `${
                                item.status === "Active"
                                  ? "#1eba1b"
                                  : "rgb(221 13 13)"
                              }`,
                              color: "white",
                              fontSize: "15px",
                              width: "6rem",
                              height: "1.7rem",
                              borderRadius: "7px",
                              border: "0px",
                            }} variant="contained" >{item.status}</label>
                      </TableCell>
                  
                      <TableCell  align="center" minWidth="100">
                      {item.from}
                      </TableCell>
                      <TableCell  align="center">
                      {item.exitTime}
                      </TableCell>
                      <TableCell  align="center">
                      {item.to}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.entryTime}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.warden}
                      </TableCell>
                      <TableCell  align="center">
                      {item.reason}
                      </TableCell>
                    
                </TableRow>
              );
            })}
          </TableBody>:<TableBody>
            {items.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map((item) => {
              return (
                <TableRow hover tabIndex={-1} key={item.code}>
                  
                      <TableCell align="center" >
                      {item.Srno}
                      </TableCell>
                  
                      <TableCell align="center" >
                      {item.name}
                      </TableCell>
                  
                      <TableCell  align="center">
                      <label style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems:"center",
                              backgroundColor: `${
                                item.status == "Active"
                                  ? "#1eba1b"
                                  : "rgb(221 13 13)"
                              }`,
                              color: "white",
                              fontSize: "15px",
                              width: "6rem",
                              height: "1.7rem",
                              borderRadius: "7px",
                              border: "0px",
                            }} variant="contained" >{item.status}</label>
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.from}
                      </TableCell>
                      <TableCell  align="center">
                      {item.exitTime}
                      </TableCell>
                      <TableCell  align="center">
                      {item.to}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.entryTime}
                      </TableCell>
                  
                      <TableCell  align="center">
                      {item.warden}
                      </TableCell>
                      <TableCell  align="center">
                      {item.reason}
                      </TableCell>
                    
                </TableRow>
              );
            })}
          </TableBody> }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
            </div>
        </Box>
    );
};

export default OutpassDataTable;
