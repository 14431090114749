const data_table = [
  {
    SrNo: "1",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "2",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "3",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "4",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "5",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "6",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "7",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "8",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "9",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "10",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "11",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "12",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "13",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "14",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "15",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "16",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "17",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "18",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "19",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "20",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "21",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "22",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "23",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "24",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "25",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
  {
    SrNo: "26",
    Name: "Abhishek Kapoor",
    Hostel_Name: "Lorem",
    Block_Name: "Lorem",
    Block_Number: "045",
  },
];

module.exports = data_table