import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import "../Warden/Warden.css";
import SecurityDataTable from "./Security_DataTable";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";

// Icon
import { MagnifyingGlass } from "phosphor-react";
import { WardenDataTable } from "../Warden/Warden_Datatable";
import { useSelector } from "react-redux";
import { CircularProgress, Paper } from "@mui/material";

const Student = () => {
  // Search input state
  const [searchInput, setSearchInput] = useState("");

  const searchInputHandler = (e) => {
    setSearchInput(e.target.value);
  };
  const { securityDetails } = useSelector((state) => state.security);
  const { uuId } = useSelector((state) => state.login);

  return (
    <>
      <div className="navmain">
        <Navbar />
      </div>
      <div className="wardenmain">
        <Container>
          <Row>
            <Col sm={12} lg={2}>
              <Sidebar />
            </Col>
            {securityDetails.length == 0 ? (
              <Paper
                sx={{
                  backgroundColor: "inherit",
                  mt: 50,
                  height: "inherit",
                  width: "100%",
                  display: "flex",
                  alignContent: "center",
                  alignItem: "center",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Paper>
            ) : (
              <Col sm={12} lg={10} className="mt-3">
                <Container>
                  <Row>
                    <Col sm={12}>
                      <div className="heading">
                        <Link to="/home">Dashboard</Link> /{" "}
                        <span className="warden-span">&nbsp; Security</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className="d-flex gap-2">
                      <InputGroup>
                        <InputGroup.Text id="basic-addon2">
                          <MagnifyingGlass size={25} color="#5c94cf" />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={searchInputHandler}
                          placeholder="Search by name"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                      </InputGroup>
                      <Link to="/securityprofile" className="button add-button">
                        + Add New
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      {!searchInput ? (
                        <SecurityDataTable />
                      ) : (
                        <SecurityDataTable searchedName={searchInput} />
                      )}
                    </Col>
                  </Row>
                </Container>
              </Col>
            )}
          </Row>
        </Container>
      </div>

      {/* <div className="warden">
        <div className="warden-search-box">
          <div className="search-input">
            <MagnifyingGlass size={25} color="#5c94cf" />
            <input
              onChange={searchInputHandler}
              placeholder="Search by name..."
              type="search"
            />
          </div>

          <Link to="/studentProfile" className="button add-button">
            + Add New
          </Link>
        </div>

        <Navbar />

        <Sidebar />

        <div className="warden-main">
          <p className="heading" style={{ top: "50px" }}>
            <Link to="/home">Dashboard</Link> /{" "}
            <span className="warden-span">&nbsp; Security</span>
          </p>
          <h2>Security Profile</h2>
          <div className="warden-data-table">
            <Button href="/securityprofile" className="add-new">
              + Add New{" "}
            </Button>
            {!searchInput ? (
              <SecurityDataTable />
            ) : (
              <SecurityDataTable searchedName={searchInput} />
            )}
          </div>

    
          <div className="security__pages-container">
            <ul>
              <li className="page-prev">
                <Link to="#">Prev</Link>
              </li>
              <li className="page-number active-page">
                <Link to="#">1</Link>
              </li>
              <li className="page-number">
                <Link to="#">2</Link>
              </li>
              <li className="page-number">
                <Link to="#">3</Link>
              </li>
              <li className="page-number">
                <Link to="#">4</Link>
              </li>
              <li className="page-number">
                <Link to="#">5</Link>
              </li>
              <li className="page-next">
                <Link to="#">Next</Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Student;
