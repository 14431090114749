import { initializeApp } from "@firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDfXdI0Wf2xb9YVqe3Dsq6FYZZ1Fc2yEqI",
  authDomain: "rgmcet-21ea4.firebaseapp.com",
  projectId: "rgmcet-21ea4",
  storageBucket: "rgmcet-21ea4.appspot.com",
  messagingSenderId: "995787558926",
  appId: "1:995787558926:web:167220bcf93422db9cec5f",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
