import React, { useState } from "react";
import "./Login.css";
import { useNavigate, useLocation } from "react-router-dom";
import img from "../img.png";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPage, setRecaptcha } from "../store/loginSlice";

const Login = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { getObj } = props;
  const navigate = useNavigate();
  const auth = getAuth();
  var number = state != null ? state.propsMobile : "";
  const [mobile, setMobile] = useState(number);

  const { loginDetails } = useSelector((state) => state.login);
  const handleOTP = async (e) => {
    e.preventDefault();
    try {
      // if (+loginDetails[0].data.mobileNumber != mobile) {
      //   console.log("bro");
      //   return;
      // }

      if (mobile === "" || mobile === undefined) return;

      let number = "+91" + mobile;

      const response = await setUpRecaptcha(number);

      if (response !== undefined) {
        getObj(response);
        dispatch(setRecaptcha(response));
        navigate("/verification", { state: { state } });
      }
    } catch (err) {
      console.log(err);
    }
  };

  function setUpRecaptcha(number) {
    let verify = new RecaptchaVerifier("recaptcha-container", {}, auth);
    verify.render();
    return signInWithPhoneNumber(auth, number, verify);
  }

  return (
    <div className="main">
      <div className="image">
        <img style={{}} src={img} alt="img" />
      </div>
      <h2 className="welcome">Welcome!</h2>

      <form class="form">
        <h3 class="title">Log In</h3>
        {/* <p class="subtitle">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur,
          optio veritatis impedit iure sint ab tempore ducimus repudiandae
          nostrum odit!
        </p> */}
        <br></br>

        <div className="mobile">Mobile Number</div>
        {/* <label htmlFor="phoneNumber">Mobile Number</label><br></br> */}
        <div class="mobile-input">
          <input
            placeholder="Enter phone number"
            name="phoneNumber"
            className="input"
            value={mobile}
            onChange={(e) => {
              setMobile(e.target.value);
            }}
          />
        </div>
        <button className="submit" onClick={handleOTP}>
          Send OTP
        </button>
      </form>
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default Login;
